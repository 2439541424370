import React from 'react';
import styles from './PageTitle.module.scss';

export interface PageTitleProps {
	titre1: string;
	titre2: string;
}

function PageTitle({ titre1, titre2 }: PageTitleProps) {
	return (
		<div className={styles.wrapper}>
			<h1 className={styles.title}>
				{titre1}
				<br />
				{titre2}
			</h1>
		</div>
	);
}

export default PageTitle;
