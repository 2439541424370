import React, { useState } from 'react';
import styles from './Contact.module.scss';
import 'whatwg-fetch';
import { stringify } from 'querystring';
import Formulaire, { Field } from '../../components/Formulaire/Formulaire';
import Head from '../../components/Head/Head';

const endpoint =
	'https://script.google.com/macros/s/AKfycbxogUxG_7PIyEaqD9jC-MGFZhAEhtB3zhiQQk_C/exec';

const order = ['prenom', 'nom', 'email', 'objet', 'message'];
const formDataNameOrder = JSON.stringify(order);

interface ResponseData {
	result: 'error' | 'success';
	data?: string;
	error?: string;
}

function Contact() {
	const [prenom, setPrenom] = useState('');
	const [nom, setNom] = useState('');
	const [email, setEmail] = useState('');
	const [objet, setObjet] = useState('');
	const [message, setMessage] = useState('');

	const [loading, setLoading] = useState(false);
	const [done, setDone] = useState(false);
	const [error, setError] = useState('');

	const fields: Field[] = [
		{ name: 'Prénom', type: 'text', onChange: setPrenom, value: prenom },
		{ name: 'Nom', type: 'text', onChange: setNom, value: nom },
		{ name: 'Email', type: 'email', onChange: setEmail, value: email },
		{ name: 'Message', type: 'textarea', onChange: setMessage, value: message },
	];

	function reset() {
		setPrenom('');
		setNom('');
		setEmail('');
		setObjet('');
		setMessage('');
	}

	async function onSubmit() {
		setLoading(true);
		setDone(false);
		setError('');

		try {
			const body = { prenom, nom, email, objet, message, formDataNameOrder };

			const res = await fetch(endpoint, {
				method: 'POST',
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
				body: stringify(body),
			});

			const { error }: ResponseData = await res.json();
			if (error) throw error;

			setDone(true);
			reset();
		} catch (err) {
			setError(err.message);
		} finally {
			setLoading(false);
		}
	}

	return (
		<div className={styles.wrapper}>
			<Head title="Contact" description="" />

			<div className={styles.left}>
				<h1 className={styles.title}>Nous écrire</h1>
				<Formulaire
					done={done}
					error={error}
					fields={fields}
					loading={loading}
					onSubmit={onSubmit}
				/>
			</div>

			<div className={styles.right}>
				<h2 className={styles.title}>Adresse</h2>
				<p>Min de rungis</p>
				<p>5 rue du poitou</p>
				<p>94150 rungis</p>
				<p>France</p>
				<a href="tel:+33 1 46 87 41 19" className={styles.numero}>
					01 46 87 41 19
				</a>
			</div>
		</div>
	);
}

export default Contact;
