// Mp4
import ete_mp4_320x240 from './ete-320x240.mp4';
import ete_mp4_640x480 from './ete-640x480.mp4';
import ete_mp4_1280x720 from './ete-1280x720.mp4';
import ete_mp4_1920x1080 from './ete-1920x1080.mp4';

// Webm
import ete_webm_320x240 from './ete-320x240.webm';
import ete_webm_640x480 from './ete-640x480.webm';
import ete_webm_1280x720 from './ete-1280x720.webm';
import ete_webm_1920x1080 from './ete-1920x1080.webm';

export default {
	mp4: {
		'320x240': ete_mp4_320x240,
		'640x480': ete_mp4_640x480,
		'1280x720': ete_mp4_1280x720,
		'1920x1080': ete_mp4_1920x1080,
	},
	webm: {
		'320x240': ete_webm_320x240,
		'640x480': ete_webm_640x480,
		'1280x720': ete_webm_1280x720,
		'1920x1080': ete_webm_1920x1080,
	},
} as VideoSources;
