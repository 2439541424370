import * as THREE from 'three';

export class Camera {
	public nativeCamera: any;

	constructor() {
		this.nativeCamera = new THREE.OrthographicCamera(0, 0, 0, 0, -10, 100);
		this.nativeCamera.position.z = 1;
		this.resize();
	}

	public resize() {
		const width = window.innerWidth;
		const height = window.innerHeight;

		this.nativeCamera.left = width / -2;
		this.nativeCamera.right = width / 2;
		this.nativeCamera.top = height / 2;
		this.nativeCamera.bottom = height / -2;
		this.nativeCamera.updateProjectionMatrix();
	}
}
