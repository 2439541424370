import React from 'react';
import styles from './SectionText.module.scss';

export interface SectionTextProps {
	className?: string;
	intro?: string;
	children: string;
	textPartOne?: string;
}

function SectionText({ className, intro, children, textPartOne }: SectionTextProps) {
	return (
		<div className={[styles.wrapper, className].filter(c => c).join(' ')}>
			{intro && <span className={styles.intro}>{intro}</span>}
			{textPartOne && <p className={styles.texte}>{textPartOne}</p>}
			<p className={styles.texte}>{children}</p>
		</div>
	);
}

export default SectionText;
