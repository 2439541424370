import React from 'react';
import styles from './PageSub.module.scss';

export interface PageSubProps {
	text1: string;
	text2: string;
	text3?: string;
}

function PageSub({ text1, text2, text3 }: PageSubProps) {
	return (
		<div className={styles.wrapper}>
			<p className={styles.phrase}>
				{text1}
				<br />
				{text2}
				<br />
				{text3}
			</p>
		</div>
	);
}

export default PageSub;
