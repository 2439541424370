import React, { useState } from 'react';
import Media from 'react-responsive';
import styles from './Automne.module.scss';
import Column from '../../components/Column/Column';
import { media } from '../../utils/breakpoints/breakpoints';
import { Parallax } from 'react-scroll-parallax';
import PageTitle from '../../components/PageTitle/PageTitle';
import PageSub from '../../components/PageSub/PageSub';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import SectionText from '../../components/SectionText/SectionText';
import figue from './img/figue.png';
import pdt from './img/pdt.png';
// import pdtRed from './img/pdt-red.png';
import pdtRed from './img/pommedeterre.jpg';
import feuille from './img/feuille.png';
import poire from './img/poire.png';
import butternut from './img/butternut.png';
import Footer from '../../components/Footer/Footer';
import Vignette from '../../components/Vignette/Vignette';
import Head from '../../components/Head/Head';
import Saison from '../../components/Saison/Saison';
import { useHistory } from 'react-router';
import metaImg from './img/automne.jpg';

// const ParallaxCache = () => {
// 	const { parallaxController } = useController();

// 	useLayoutEffect(() => {
// 		const handler = () => parallaxController.update();
// 		window.addEventListener('load', handler);
// 		return () => window.removeEventListener('load', handler);
// 	}, [parallaxController]);

// 	return null;
// };

function Automne() {
	const [isTablet] = useState(window.innerWidth <= 1024);
	const [clickSaison, setclickSaison] = useState(false);
	const [saison, setSaison] = useState(false);
	const history = useHistory();

	function retourHome() {
		setclickSaison(true);
		setSaison(true);
		setTimeout(() => {
			history.push({
				pathname: '/',
				state: { clickSaison: true },
			});
		}, 1550);
	}

	return (
		<div className={styles.page}>
			<Head title="Automne" description="" img={metaImg} />

			{/* <Column className={styles.page}> */}
			<section className={styles.hautPage}>
				<img
					className={[styles.butternut, styles.imgGauche].join(' ')}
					src={butternut}
					alt=""
				/>
				<img
					className={[styles.feuille, styles.imgDroite].join(' ')}
					src={feuille}
					alt=""
				/>
				{/* <Parallax disabled={isTablet} y={['25px', '-75px']}> */}
				<PageTitle titre1="Des parfums" titre2="Chaleureux" />
				{/* </Parallax> */}
				{/* <Parallax disabled={isTablet} y={['50px', '-100px']}> */}
				<PageSub
					text1="« Place aux recettes gourmandes"
					text2="aux saveurs automnale »"
					text3=""
				/>
				{/* </Parallax> */}
			</section>
			<section className={styles.partie1}>
				<div className={styles.wrapperSection}>
					<Column className={[styles.img, styles.imgAnim].join(' ')}>
						<Parallax disabled={isTablet} y={['80px', '-100px']}>
							<img src={figue} alt="" />
						</Parallax>
						<Parallax disabled={isTablet} y={['100px', '-100px']}>
							<Vignette
								className={styles.vignetteFigue}
								titre="+750"
								text="variétés de figues sont
									recensées dans le monde"
							/>
						</Parallax>
					</Column>
					<Column className={[styles.text, styles.textAnim].join(' ')}>
						<Parallax disabled={isTablet} y={['-80px', '100px']}>
							<SectionTitle gauche titre1="Savoureuse" titre2="et moelleuse" />
							<SectionText
								intro="Les figues Napoli"
								textPartOne="Découverte il y a plus de dix mille an, la figue est aujourd'hui apréciée dans
								le monde entier grâce à sa chair onctueuse et rafraîchissante.
								La notre provient des cultures du producteur Napoli, grand amoureux des
								beaux produits, qui produit ses figues noires à Perpignan."
							>
								Véritable fesival de couleurs et de saveurs, ce fruit gorgé de soleil se
								déguste de Juillet à Octobre et saura agrémenter vos plats sucrés et salés.
							</SectionText>
						</Parallax>
					</Column>
				</div>
			</section>
			<section className={styles.partie2}>
				<div className={styles.wrapperSection}>
					<Media query={media['>mobile']}>
						<div className={styles.wrapper}>
							<Parallax disabled={isTablet} y={['-80px', '100px']}>
								<SectionTitle titre1="Une histoire" titre2="de famille" />
							</Parallax>

							<Column className={styles.img}>
								<Parallax disabled={isTablet} y={['-50px', '50px']}>
									<img src={pdt} alt="" />
								</Parallax>
								<Parallax disabled={isTablet} y={['150px', '0px']}>
									<Vignette
										className={styles.vignettePdt}
										titre="Agria de 
											Champagne"
										text="100 ha de pommes de
											terre Agria sont plantés
											chaque année pour nos
											maisons en Champagne."
									/>
								</Parallax>
							</Column>
						</div>
						<Parallax disabled={isTablet} y={['80px', '-100px']}>
							<Column className={styles.text}>
								<SectionText intro="Pomme de terre fritable">
									Nous travaillons depuis quelques années en étroite collaboration avec
									l'agriculteur passionné Damien Boude, qui cultive pur nous en Champagne
									la variété Agria sur 100 ha. Grâce à son savoir-faire, nous vous
									proposons une pomme de terre 100% française, idéale pour les frites
									maison. Disponible de Septembre à Mai, cette pomme de terre à chaire
									ferme offre croustillance et goût pour garantir la qualité de vos recettes.
								</SectionText>
							</Column>

							<Column className={styles.img2}>
								<img src={pdtRed} alt="" />
							</Column>
						</Parallax>
					</Media>
					<Media query={media['<=mobile']}>
						<Column className={styles.text}>
							<SectionTitle titre1="Une histoire" titre2="de famille" />
							<SectionText intro="Pomme de terre fritable">
								Nous travaillons depuis quelques années en étroite collaboration avec
								l'agriculteur passionné Damien Boude, qui cultive pur nous en Champagne
								la variété Agria sur 100 ha. Grâce à son savoir-faire, nous vous
								proposons une pomme de terre 100% française, idéale pour les frites
								maison. Disponible de Septembre à Mai, cette pomme de terre à chaire
								ferme offre croustillance et goût pour garantir la qualité de vos recettes.
							</SectionText>
						</Column>
						<div className={styles.wrapper}>
							<Column className={styles.img}>
								<img src={pdt} alt="" />
							</Column>
						</div>
						<div className={styles.wrapper2}>
							<Vignette
								className={[styles.vignettePdt, styles.vignette].join(' ')}
								titre="Agria de 
									Champagne"
								text="100 ha de pommes de
									terre Agria sont plantés
									chaque année pour nos
									maisons en Champagne."
							/>
						</div>
						<div className={styles.wrapper3}>
							<img src={pdtRed} alt="" />
						</div>
					</Media>
				</div>
			</section>

			<section
				className={[
					styles.partie3,
					clickSaison ? styles.deconstruct : styles.off,
				].join(' ')}
			>
				<div className={styles.wrapperSection}>
					<Media query={media['>mobile']}>
						<Column className={styles.img3}>
							<Parallax disabled={isTablet} y={['80px', '-100px']}>
								<img src={poire} alt="" />
							</Parallax>
							<Parallax disabled={isTablet} y={['150px', '-100px']}>
								<Vignette
									className={styles.vignettePoire}
									titre="L'automne"
									text="est la saison la plus propice
										pour déguster des poires."
								/>
							</Parallax>
						</Column>

						<Column className={styles.text}>
							<Parallax disabled={isTablet} y={['-150px', '150px']}>
								<SectionTitle
									className={[styles.titre, styles.titrePoireG].join(' ')}
									gauche
									titre1="Douce et"
									titre2="gourmande"
								/>
								<Media query={media['>laptop']}>
									<SectionText
										intro="Les poires"
										className={styles.text3Desktop}
										textPartOne="Les poires d'automne sont la Comice, la William, la Conférence, la
										Beurré Hardy, la Louise Bonne, la Sweet sensation et la Curé."
									>
										À chaque variété : sa forme, sa chaire, et son goût qui lui est propre.
										Nos poires proviennent des «Vergers de la Valloire», producteur
										situé dans la Drôme. Partisan de l'agriculture raisonnée, il produit
										des poires depuis 4 générations.
									</SectionText>
								</Media>
							</Parallax>
						</Column>
					</Media>
					<Media query={media['<=mobile']}>
						<Column className={styles.text}>
							<SectionTitle
								className={styles.titre}
								gauche
								titre1="Douce et"
								titre2="gourmande"
							/>
							<SectionText
								intro="Les poires"
								textPartOne="Les poires d'automne sont la Comice, la William, la Conférence, la
									Beurré Hardy, la Louise Bonne, la Sweet sensation et la Curé."
							>
								À chaque variété : sa forme, sa chaire, et son goût qui lui est propre.
								Nos poires proviennent des «Vergers de la Valloire», producteur
								situé dans la Drôme. Partisan de l'agriculture raisonnée, il produit
								des poires depuis 4 générations.
							</SectionText>
						</Column>
						<Column className={styles.img3}>
							<img src={poire} alt="" />

							<Vignette
								className={styles.vignettePoire}
								titre="L'automne"
								text="est la saison la plus propice
									pour déguster des poires."
							/>
						</Column>
					</Media>
				</div>
				<Media query={media['<=laptop']}>
					<Media query={media['>mobile']}>
						<SectionText
							className={styles.text3}
							intro="Les poires"
							textPartOne="Les poires d'automne sont la Comice, la William, la Conférence, la
								Beurré Hardy, la Louise Bonne, la Sweet sensation et la Curé."
						>
							À chaque variété : sa forme, sa chaire, et son goût qui lui est propre.
							Nos poires proviennent des «Vergers de la Valloire», producteur
							situé dans la Drôme. Partisan de l'agriculture raisonnée, il produit
							des poires depuis 4 générations.
						</SectionText>
					</Media>
				</Media>
				<Footer></Footer>
			</section>
			<div
				className={[styles.saison, saison ? styles.center : styles.off].join(
					' ',
				)}
				onClick={retourHome}
			>
				<Saison clickSaison={clickSaison}></Saison>
			</div>
		</div>
	);
}

export default Automne;
