import React from 'react';
import styles from './Formulaire.module.scss';

export interface Field {
	value: string;
	name: string;
	onChange: (value: string) => void;
	type: 'text' | 'email' | 'textarea';
}

interface FormulaireProps {
	onSubmit: () => void;

	loading: boolean;
	done: boolean;
	error: string;

	fields: Field[];
}

function Formulaire({
	done,
	error,
	fields,
	loading,
	onSubmit,
}: FormulaireProps) {
	return (
		<form
			className={styles.wrapper}
			onSubmit={e => {
				e.preventDefault();
				onSubmit();
			}}
		>
			{fields.map(({ onChange, type, value, name }, i) => {
				const Input = type === 'textarea' ? 'textarea' : 'input';

				const handleChange = (
					e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
				) => onChange(e.target.value);

				return (
					<Input
						key={i}
						required
						type={type}
						value={value}
						disabled={loading}
						placeholder={name}
						onChange={handleChange}
						className={[styles.input, type === 'textarea' && styles.textarea]
							.filter(Boolean)
							.join(' ')}
					/>
				);
			})}

			{error && <p className={styles.error}>{error}</p>}

			{done && (
				<p className={styles.success}>
					Votre message a bien été envoyé, merci !
				</p>
			)}

			<button type="submit" disabled={loading} className={styles.submit}>
				Envoyer
			</button>
		</form>
	);
}

export default Formulaire;
