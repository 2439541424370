import React, { useState, Fragment } from 'react';
import styles from './Histoire.module.scss';
import Column from '../../components/Column/Column';
import Media from 'react-responsive';
import { media } from '../../utils/breakpoints/breakpoints';
import { Parallax } from 'react-scroll-parallax';
import PageTitle from '../../components/PageTitle/PageTitle';
import PageSub from '../../components/PageSub/PageSub';
import Vignette from '../../components/Vignette/Vignette';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import SectionText from '../../components/SectionText/SectionText';
import carottes from './img/carottes.jpg';
import cerise from './img/cerise-coeur.jpg';
import graines from './img/graines.png';
import camion from './img/camion.jpg';
import livraison from './img/livraison.png';
import client from './img/client.png';
import chariot from './img/chariot.png';
import ab from './img/ab.png';
import idf from './img/idf.png';
import pousse from './img/pousse.png';
import Saison from '../../components/Saison/Saison';
import { useHistory } from 'react-router';
import Head from '../../components/Head/Head';

function Histoire() {
	const [isTablet] = useState(window.innerWidth <= 1024);
	const [isMobile] = useState(window.innerWidth <= 500);
	const [clickSaison, setclickSaison] = useState(false);
	const [saison, setSaison] = useState(false);
	const history = useHistory();

	function retourHome() {
		setclickSaison(true);
		setSaison(true);
		setTimeout(() => {
			history.push({
				pathname: '/',
				state: { clickSaison: true },
			});
		}, 1550);
	}

	function reformateText() {
		if (isMobile) {
			return (
				<Fragment>
					<Column className={[styles.text, styles.textAnim].join(' ')}>
						<Parallax disabled={isTablet} y={['-80px', '100px']}>
							<p className={styles.mobileP}>
								Son histoire, c’est donc celle de familles portées par l’amour
								de la terre et de ceux qui la cultivent. Les grands-parents
								Charraire étaient quant à eux les rois de la pomme de terre,
								fournisseurs des restaurateurs parisiens. Depuis, ils livrent
								tous les fruits & légumes de saison & de qualité aux meilleurs
								chefs français et étrangers. Aujourd’hui ? C’est le moment
								d’aller plus loin en diffusant l’excellence des terroirs. Chaque
								jours, les acheteurs des Halles Trottemant sourcent les
								meilleurs producteurs et des produits exceptionnels partout en
								France, en Europe et dans le monde.
							</p>
						</Parallax>
					</Column>
					<Column className={[styles.img, styles.imgAnim].join(' ')}>
						<Parallax disabled={isTablet} y={['80px', '-100px']}>
							<img src={cerise} alt="" />
						</Parallax>
						<Parallax disabled={isTablet} y={['150px', '-100px']}>
							<Vignette
								className={styles.vignettePdt}
								// titre=""
								text="L'histoire des Halles
									Trottemant est celle de 
									familles portées par 
									l'amour de la terre et de 
									ceux qui la cultivent."
							/>
						</Parallax>
					</Column>
					<Column className={[styles.text, styles.textAnim].join(' ')}>
						<Parallax disabled={isTablet} y={['-80px', '100px']}>
							<SectionTitle gauche titre1="notre" titre2="histoire" />
							<SectionText>
								L’Histoire commence pendant les Trente Glorieuses au Pavillon 7
								du « Ventre de Paris ». Installée au cœur du 1er arrondissement
								de la capitale, une famille livre alors les meilleurs
								restaurateurs et la fine fleur de la gastronomie française. En
								1982, elle décide de s’installer à Rungis et rejoint en 2002 la
								famille Charraire.
							</SectionText>
						</Parallax>
					</Column>
				</Fragment>
			);
		} else {
			return (
				<Fragment>
					<Column className={[styles.img, styles.imgAnim].join(' ')}>
						<Parallax disabled={isTablet} y={['80px', '-100px']}>
							<img src={cerise} alt="" />
						</Parallax>
						<Parallax disabled={isTablet} y={['150px', '-100px']}>
							<Vignette
								className={styles.vignettePdt}
								// titre=""
								text="L'histoire des Halles
									Trottemant est celle de 
									familles portées par 
									l'amour de la terre et de 
									ceux qui la cultivent."
							/>
						</Parallax>
					</Column>
					<Column className={[styles.text, styles.textAnim].join(' ')}>
						<Parallax disabled={isTablet} y={['-80px', '100px']}>
							<SectionTitle gauche titre1="notre" titre2="histoire" />
							<SectionText>
								Tout commence au Pavillion 7 du Ventre de Paris. Installée au coeur du 1er
								arrondissement de la capitale, une famille livre alors les meilleurs
								restaurateurs et la fine fleur de la gastronomie française. En 1982, elle
								décide de s'installer à Rungis et rejoint en 2002 la famille Charraire.
							</SectionText>
						</Parallax>
					</Column>
				</Fragment>
			);
		}
	}

	return (
		<div className={styles.page}>
			<Head title="Notre histoire" description="" />

			{/* <Column className={styles.page}> */}
			<section className={styles.hautPage}>
				<img
					className={[styles.graines, styles.imgGauche].join(' ')}
					src={graines}
					alt=""
				/>
				<img
					className={[styles.pousse, styles.imgDroite].join(' ')}
					src={pousse}
					alt=""
				/>
				{/* <Parallax disabled={isTablet} y={['25px', '-75px']}> */}
				<PageTitle titre1="les halles" titre2="trottemant" />
				{/* </Parallax> */}
				{/* <Parallax disabled={isTablet} y={['50px', '-50px']}> */}
				<PageSub
					text1="« Dans toutes les choses de la nature, il y a"
					text2="quelque  chose de merveilleux. »"
					text3=""
				/>
				<p className={styles.aristote}>Aristote</p>
				{/* </Parallax> */}
			</section>
			<section className={styles.partie1}>
				<div className={styles.wrapperSection}>{reformateText()}</div>
			</section>

			<section className={styles.partie2}>
				<div className={styles.wrapperSection}>
					<Media query={media['>mobile']}>
						<div className={styles.wrapper}>
							<SectionTitle
								className={styles.title}
								titre1=""
								titre2="Savoir-faire"
							/>

							<Column className={styles.img}>
								<Parallax disabled={isTablet} y={['-50px', '50px']}>
									<img src={camion} alt="" />
								</Parallax>
								<Parallax disabled={isTablet} y={['150px', '-100px']}>
									<Vignette
										className={styles.vignetteCamion}
										text="Ouvert 7j 7"
										titre="- 24h/24"
									/>
								</Parallax>
							</Column>
						</div>

						<Column className={styles.text + ' ' + styles.textCamion}>
							<SectionText>
								Grâce à notre organisation unique et l’expertise que nous avons
								bâtit d’années en années, nos clients peuvent commander le jour
								A avant 1 heure du matin et se faire livrer le jour B à l’heure
								souhaitée. Notre équipe de commerciaux est au contact permanent
								de nos clients afin de connaître leur besoin.
							</SectionText>
						</Column>
						<Column className={[styles.text, styles.icon].join(' ')}>

							<p className={styles.services}>Services</p>

							<div className={styles.dflex}>
								<div className={styles.part}>
									<img src={client} alt='' width='110px' height='110px' />
									<p className={styles.subTitle}>Commande<br /> client</p>
									<p className={styles.content}>24H24</p>
								</div>
								<div className={styles.part}>
									<img src={chariot} alt='' width='110px' height='110px' />
									<p className={styles.subTitle}>Préparation</p>
									<p className={styles.content}>18h30 à 4h30</p>
								</div>
								<div className={styles.part}>
									<img src={livraison} alt='' width='150px' height='110px' />
									<p className={styles.subTitle}>Livraison</p>
									<p className={styles.content}>4h à 12h</p>
								</div>
							</div>

						</Column>
					</Media>
					<Media query={media['<=mobile']}>
						<Column className={styles.text}>
							<SectionTitle
								titre1=""
								titre2="Savoir-faire"
							/>
							<SectionText>
								Grâce à notre organisation unique et l’expertise que nous avons
								bâtit d’années en années, nos clients peuvent commander le jour
								A avant 1 heure du matin et se faire livrer le jour B à l’heure
								souhaitée. Notre équipe de commerciaux est au contact permanent
								de nos clients afin de connaître leur besoin.
							</SectionText>
						</Column>
						<div className={styles.wrapper}>
							<Column className={styles.img}>
								<Parallax disabled={isTablet} y={['-50px', '50px']}>
									<img src={camion} alt="" />
								</Parallax>
								<Parallax disabled={isTablet} y={['150px', '-100px']}>
									<Vignette
										className={styles.vignetteCamion}
										text="Ouvert 7j 7"
										titre="- 24h/24"
									/>
								</Parallax>
							</Column>
						</div>
						<Column className={styles.icon}>

							<p className={styles.services}>Services</p>

							<div className={styles.dflex}>
								<div className={styles.part}>
									<img src={client} alt='' className={styles.iconPartie2} height='70px' />
									<p className={styles.subTitle}>Commande<br /> client</p>
									<p className={styles.content}>24H24</p>
								</div>
								<div className={styles.part}>
									<img src={chariot} alt='' className={styles.iconPartie2} height='70px' />
									<p className={styles.subTitle}>Préparation</p>
									<p className={styles.content}>18h30 à 4h30</p>
								</div>
								<div className={styles.part}>
									<img src={livraison} alt='' className={styles.iconPartie2} height='70px' />
									<p className={styles.subTitle}>Livraison</p>
									<p className={styles.content}>4h à 12h</p>
								</div>
							</div>

						</Column>
						<div className={styles.wrapper2}></div>
					</Media>
				</div>
			</section>

			<section
				className={[
					styles.partie1,
					styles.inverted,
					clickSaison ? styles.deconstruct : styles.off,
				].join(' ')}
			>
				<div className={styles.wrapperSection}>
					<Column className={[styles.img, styles.img3].join(' ')}>
						<Parallax disabled={isTablet} y={['80px', '-100px']}>
							<img src={carottes} alt="Carottes" className={styles.imgCarrottes} />
						</Parallax>
						<Parallax disabled={isTablet} y={['150px', '-100px']}></Parallax>
					</Column>
					<Column className={[styles.text, styles.text3].join(' ')}>
						<Parallax disabled={isTablet} y={['-80px', '100px']}>
							<SectionTitle
								className={styles.titre}
								gauche
								titre1="ENGAGÉ POUR"
								titre2="LA QUALITÉ"
								titre3="ET LA DURABILITÉ"
							/>
							<SectionText
								className={styles.textEnd}
								textPartOne="Conscients des enjeux environnementaux et des préoccupations de nos
									clients, nous n’avons de cesse de développer notre offre de fruits et
									légumes locaux et issus de l’agriculture raisonnée et biologique. Depuis
									2014, nous avons créé le Comptoir des Producteurs qui source pour la
									Maison Halles Trottemant des produits aux qualités gustatives incomparables
									au cœur des campagnes françaises.
									Nous sommes depuis toujours à la recherche de petits producteurs afin de
									soutenir le savoir-faire agricole français et de valoriser le territoire et les
									produits nationaux."
							>
								Notre ambition première est de proposer une offre de consommation
								durable et resonsable, d'une qualité exceptionnelle et de saison.
							</SectionText>
						</Parallax>
						<div className={styles.divImgAgriculture}>
							<img src={ab} alt="Agriculture Biologique" className={styles.imgAB} />
							<img src={idf} alt="Ile-de-France" className={styles.imgIDF} />
						</div>
					</Column>
				</div>
			</section>
			<div
				className={[
					styles.end,
					clickSaison ? styles.deconstruct : styles.off,
				].join(' ')}
			>
				<p>
					Envie d’en découvrir plus sur nous <br />
					ou de nous rencontrer ?
				</p>
				<a href="/contact">CONTACTEZ-NOUS</a>
			</div>
			<div
				className={[styles.saison, saison ? styles.center : styles.off].join(
					' ',
				)}
				onClick={retourHome}
			>
				<Saison clickSaison={clickSaison}></Saison>
			</div>
		</div>
	);
}

export default Histoire;
