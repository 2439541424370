import React, { ReactNode } from 'react';
import { Route } from 'react-router';
import { CSSTransition } from 'react-transition-group';
import './AnimatedRoute.scss';

export const pageTransitionDuration = 1000;

interface AnimatedRouteProps {
	path: string;
	children: ReactNode;
}

function AnimatedRoute({ path, children }: AnimatedRouteProps) {
	return (
		<Route exact path={path}>
			{({ match }) => (
				<CSSTransition
					in={!!match}
					unmountOnExit
					classNames={'page'}
					timeout={pageTransitionDuration}
				>
					<div>{children}</div>
				</CSSTransition>
			)}
		</Route>
	);
}

export default AnimatedRoute;
