import * as THREE from 'three';

interface RendererConstructor {
	canvas: HTMLCanvasElement;
	scene: THREE.Scene;
}

interface ParamRender {
	scene: THREE.Scene;
	camera: THREE.Camera;
	fbo: THREE.WebGLRenderTarget | null;
	clear: boolean;
}

export class Renderer {
	private nativeRenderer: THREE.WebGLRenderer;
	private scene: THREE.Scene;
	private canvas: HTMLCanvasElement;

	constructor({ canvas, scene }: RendererConstructor) {
		this.nativeRenderer = new THREE.WebGLRenderer({
			canvas,
			alpha: true,
			antialias: true,
		});

		this.nativeRenderer.setPixelRatio(window.devicePixelRatio);
		this.nativeRenderer.setClearColor(new THREE.Color(0xffffff));
		this.scene = scene;
		this.canvas = canvas;
		this.resize();
	}

	public update(scene: THREE.Scene) {
		this.scene = scene;
	}

	public resize() {
		this.nativeRenderer.setSize(window.innerWidth, window.innerHeight);
	}

	public render = (param: ParamRender) => {
		if (param.fbo) {
			this.nativeRenderer.setRenderTarget(param.fbo);

			this.nativeRenderer.clear(param.clear);
			this.nativeRenderer.render(param.scene, param.camera);
		} else {
			this.nativeRenderer.setRenderTarget(null);
			this.nativeRenderer.setPixelRatio(window.devicePixelRatio);
			this.nativeRenderer.clear(false);
			this.nativeRenderer.render(param.scene, param.camera);
		}
	};

	public destroy() {
		this.nativeRenderer.dispose();
	}
}
