import React, { useState } from 'react';
import Media from 'react-responsive';
import styles from './Ete.module.scss';
import Column from '../../components/Column/Column';
import { media } from '../../utils/breakpoints/breakpoints';
import { Parallax } from 'react-scroll-parallax';
import PageTitle from '../../components/PageTitle/PageTitle';
import PageSub from '../../components/PageSub/PageSub';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import SectionText from '../../components/SectionText/SectionText';
import cerise from './img/cerise.png';
// import melon from './img/melon.png';
import melon from './img/melon.jpg';
import melon2 from './img/melon2.png';
import feuille from './img/feuille.png';
// import raisin from './img/raisin.png';
import raisin from './img/raisin.jpg';
// import tomates from './img/tomates.png';
import tomates from './img/tomates.jpg';
import Vignette from '../../components/Vignette/Vignette';
import Footer from '../../components/Footer/Footer';
import Saison from '../../components/Saison/Saison';
import { useHistory } from 'react-router';
import Head from '../../components/Head/Head';
import metaImg from './img/ete.jpg';

// const ParallaxCache = () => {
// 	const { parallaxController } = useController();

// 	useLayoutEffect(() => {
// 		const handler = () => parallaxController.update();
// 		window.addEventListener('load', handler);
// 		return () => window.removeEventListener('load', handler);
// 	}, [parallaxController]);

// 	return null;
// };

function Ete() {
	const [isTablet] = useState(window.innerWidth <= 1024);
	const [clickSaison, setclickSaison] = useState(false);
	const [saison, setSaison] = useState(false);
	const history = useHistory();

	function retourHome() {
		setclickSaison(true);
		setSaison(true);
		setTimeout(() => {
			history.push({
				pathname: '/',
				state: { clickSaison: true },
			});
		}, 1550);
	}

	return (
		<div className={styles.page}>
			<Head title="Été" description="" img={metaImg} />

			{/* <Column className={styles.page}> */}
			<section className={styles.hautPage}>
				<img
					className={[styles.cerise, styles.imgGauche].join(' ')}
					src={cerise}
					alt=""
				/>
				<img
					className={[styles.feuille, styles.imgDroite].join(' ')}
					src={feuille}
					alt=""
				/>
				{/* <Parallax disabled={isTablet} y={['25px', '-75px']}> */}
				<PageTitle titre1="La saveur" titre2="du soleil" />
				{/* </Parallax> */}
				{/* <Parallax disabled={isTablet} y={['50px', '-50px']}> */}
				<PageSub
					text1="« Un parfum d'été »"
					text2=""
					text3=""
				/>
				{/* </Parallax> */}
			</section>
			<section className={styles.partie1}>
				<div className={styles.wrapperSection}>
					<Column className={[styles.img, styles.imgAnim].join(' ')}>
						<Parallax disabled={isTablet} y={['80px', '-100px']}>
							<img src={tomates} alt="" />
						</Parallax>
						<Parallax disabled={isTablet} y={['150px', '-100px']}>
							<Vignette
								className={styles.vignetteTomate}
								// titre=""
								text="Il existe plus d'une
									dizaine de variétés de
									tomates anciennes de
									couleurs et formes
									différentes."
							/>
						</Parallax>
					</Column>
					<Column className={[styles.text, styles.textAnim].join(' ')}>
						<Parallax disabled={isTablet} y={['-80px', '100px']}>
							<SectionTitle className={styles.titleTomate} gauche titre1="De la couleur " titre2="dans l'assiette" />
							<SectionText
								intro="Les tomates anciennes de chez Huitric"
								textPartOne="Amoureux du goût et de la qualité, notre produteur français Huitric exprime
									sa passion du métier à travers une exigence et un savoir faire admirable !
									Situés au cœur des marais salants de Guérande, ces maraîchers pratiquent
									l'agriculture raisonnée et sont à la recherche permanente de produits rares à
									mettre en culture pour le plus grand bonheur des marchés de la région de La
									Baule."
							>
								La saison de leurs tomates anciennes s'étend de Mai à Octobre.
							</SectionText>
						</Parallax>
					</Column>
				</div>
			</section>
			<section className={styles.partie2}>
				<div className={styles.wrapperSection}>
					<Media query={media['>mobile']}>
						<div className={styles.wrapper}>
							<Parallax disabled={isTablet} y={['-80px', '100px']}>
								<SectionTitle titre1="le plus " titre2="fruité des légumes" />
							</Parallax>

							<Column className={styles.img}>
								<Parallax disabled={isTablet} y={['-50px', '50px']}>
									<img src={melon} alt="" />
								</Parallax>
								<Vignette
									className={styles.vignetteMelon}
									titre="Juin à
										Septembre"
									text="Fruit emblématique de 
										l'été, il se déguste en
										pleine saison de
										Juin à Septembre."
								/>
							</Column>
						</div>
						<Parallax disabled={isTablet} y={['80px', '-100px']}>
							<Column className={styles.text}>
								<SectionText
									intro="Le melon français"
									textPartOne="Nos melons provienne de l'exploitation familiale
									« Les Rubis de Lansac », petit producteur situé à
									Avignon depuis 2 générations."
								>
									Ces professionnels de la terre cultivent leurs melons
									avec un savoir-faire unique garantissant des produits
									au goût incomparable.
								</SectionText>
							</Column>

							<Column className={styles.img2}>
								<img src={melon2} alt="" />
							</Column>
						</Parallax>
					</Media>
					<Media query={media['<=mobile']}>
						<Column className={styles.text}>
							<SectionTitle titre1="le plus " titre2="fruité des légumes" />
							<SectionText
								intro="Le melon français"
								textPartOne="Nos melons provienne de l'exploitation familiale
								« Les Rubis de Lansac », petit producteur situé à
								Avignon depuis 2 générations."
							>
								Ces professionnels de la terre cultivent leurs melons
								avec un savoir-faire unique garantissant des produits
								au goût incomparable.
							</SectionText>
						</Column>
						<div className={styles.wrapper}>
							<Column className={styles.img}>
								<Parallax disabled={isTablet} y={['-50px', '50px']}>
									<img src={melon} alt="" />
								</Parallax>
							</Column>
						</div>
						<div className={styles.wrapper2}>
							<Vignette
								className={[styles.vignetteMelon, styles.vignette].join(' ')}
								titre="Juin à
									Septembre"
								text="Fruit emblématique de 
									l'été, il se déguste en
									pleine saison de
									Juin à Septembre."
							/>
						</div>
						<div className={styles.wrapper3}>
							<img src={melon2} alt="" />
						</div>
					</Media>
				</div>
			</section>

			<section
				className={[
					styles.partie3,
					clickSaison ? styles.deconstruct : styles.off,
				].join(' ')}
			>
				<div className={styles.wrapperSection}>
					<Media query={media['>mobile']}>
						<Column className={styles.img3}>
							<Parallax disabled={isTablet} y={['80px', '-100px']}>
								<img src={raisin} alt="" />
							</Parallax>
							<Parallax disabled={isTablet} y={['150px', '-100px']}>
								<Vignette
									className={styles.vignetteRaisin}
									// titre="15000 t."
									text="Notre raisin Muscat provient
										de notre petit producteur
										Les Mallauques, qui produit
										en région PACA depuis 4
										générations dans la plus 
										pure tradition de cet AOC
										prestigieux : « Le Muscat du 
										Ventoux »."
								/>
							</Parallax>
						</Column>

						<Column className={styles.text}>
							<Parallax disabled={isTablet} y={['-80px', '100px']}>
								<Media query={media['>laptop']}>
									<SectionTitle
										className={styles.titre}
										gauche
										titre1="arôme"
										titre2="sucré"
									/>
								</Media>
								<Media query={media['<=laptop']}>
									<SectionTitle
										className={styles.titre}
										gauche
										titre1="arôme"
										titre2="sucré"
									/>
								</Media>
								<Media query={media['>laptop']}>
									<SectionText
										intro="Le raisin Muscat…"
										className={styles.text3Desktop}
										textPartOne="Consommé depuis l'Egypte Antique, le raisin
											comporte de multiple variétés. Parmis elles : le
											muscat, qui nécessite beaucoup de chaleur et de 
											soleil pour sa culture et dont le cépage peut être blanc,
											rose, rouge ou noir."
									>
										Il se déguste de Août à Octobre.
									</SectionText>
								</Media>
							</Parallax>
						</Column>
					</Media>
					<Media query={media['<=mobile']}>
						<Column className={styles.text}>
							<Parallax disabled={isTablet} y={['-80px', '100px']}>
								<SectionTitle
									className={styles.titre}
									gauche
									titre1="arôme"
									titre2="sucré"
								/>
								<SectionText
									intro="Le raisin Muscat…"
									textPartOne="Consommé depuis l'Egypte Antique, le raisin
										comporte de multiple variétés. Parmis elles : le
										muscat, qui nécessite beaucoup de chaleur et de 
										soleil pour sa culture et dont le cépage peut être blanc,
										rose, rouge ou noir."
								>
									Il se déguste de Août à Octobre.
								</SectionText>
							</Parallax>
						</Column>
						<Column className={styles.img3}>
							<Parallax disabled={isTablet} y={['80px', '-100px']}>
								<img src={raisin} alt="" />
							</Parallax>
							<Vignette
								className={styles.vignetteRaisin}
								// titre="15000 t."
								text="Notre raisin Muscat provient
									de notre petit producteur
									Les Mallauques, qui produit
									en région PACA depuis 4
									générations dans la plus 
									pure tradition de cet AOC
									prestigieux : « Le Muscat du 
									Ventoux »."
							/>
						</Column>
					</Media>
				</div>
				<Media query={media['<=laptop']}>
					<Media query={media['>mobile']}>
						<SectionText
							className={styles.text3}
							intro="Le raisin Muscat…"
							textPartOne="Consommé depuis l'Egypte Antique, le raisin
								comporte de multiple variétés. Parmis elles : le
								muscat, qui nécessite beaucoup de chaleur et de 
								soleil pour sa culture et dont le cépage peut être blanc,
								rose, rouge ou noir."
						>
							Il se déguste de Août à Octobre.
						</SectionText>
					</Media>
				</Media>
				<Footer></Footer>
			</section>
			<div
				className={[styles.saison, saison ? styles.center : styles.off].join(
					' ',
				)}
				onClick={retourHome}
			>
				<Saison clickSaison={clickSaison}></Saison>
			</div>
			{/* <ParallaxCache /> */}
		</div>
	);
}

export default Ete;
