import React, { useState } from 'react';
import Media from 'react-responsive';
import styles from './Printemps.module.scss';
import Column from '../../components/Column/Column';
import { media } from '../../utils/breakpoints/breakpoints';
import { Parallax } from 'react-scroll-parallax';
import PageTitle from '../../components/PageTitle/PageTitle';
import PageSub from '../../components/PageSub/PageSub';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import SectionText from '../../components/SectionText/SectionText';
import fraise from './img/fraise.png';
import asperge from './img/asperge.png';
import radis from './img/radis.png';
import artichaut from './img/artichaut.png';
import radisCoupe from './img/radisCoupe.png';
import fleur from './img/fleur.png';
import Vignette from '../../components/Vignette/Vignette';
import Footer from '../../components/Footer/Footer';
import Saison from '../../components/Saison/Saison';
import { useHistory } from 'react-router';
import metaImg from './img/printemps.jpg';
import Head from '../../components/Head/Head';

function Printemps() {
	const [isTablet] = useState(window.innerWidth <= 1024);
	const [clickSaison, setclickSaison] = useState(false);
	const [saison, setSaison] = useState(false);
	const history = useHistory();

	function retourHome() {
		setclickSaison(true);
		setSaison(true);
		setTimeout(() => {
			history.push({
				pathname: '/',
				state: { clickSaison: true },
			});
		}, 1550);
	}

	return (
		<div className={styles.page}>
			<Head title="Printemps" description="" img={metaImg} />

			{/* <Column className={styles.page}> */}
			<section className={styles.hautPage}>
				<img
					className={[styles.artichaut, styles.imgGauche].join(' ')}
					src={artichaut}
					alt=""
				/>
				<img
					className={[styles.fleur, styles.imgDroite].join(' ')}
					src={fleur}
					alt=""
				/>
				{/* <Parallax disabled={isTablet} y={['25px', '-75px']}> */}
				<PageTitle titre1="Fraicheurs" titre2="printannières" />
				{/* </Parallax> */}
				{/* <Parallax disabled={isTablet} y={['50px', '-50px']}> */}
				<PageSub
					text1="« Le premiers rayons de soleil"
					text2="dans l'assiette »"
				/>
				{/* </Parallax> */}
			</section>
			<section className={styles.partie1}>
				<div className={styles.wrapperSection}>
					<Column className={[styles.img, styles.imgAnim].join(' ')}>
						<Parallax disabled={isTablet} y={['80px', '-100px']}>
							<img src={asperge} alt="" />
						</Parallax>
						<Parallax disabled={isTablet} y={['150px', '-100px']}>
							<Vignette
								className={styles.vignetteAsperge}
								// titre=""
								text="Frédéric Poupard,
									producteur d'asperges en
									Pays de la Loire, a pris la
									relève de son père à la tête
									d'une exploitation de 70
									hectares... entièrement en
									agriculture biologiques !"
							/>
						</Parallax>
					</Column>
					<Column className={[styles.text, styles.textAnim].join(' ')}>
						<Parallax disabled={isTablet} y={['-80px', '100px']}>
							<SectionTitle gauche titre1="le bourgeon" titre2="du printemps" />
							<SectionText
								intro="L’asperge…"
								textPartOne="Nos asperges proviennent des récoltes de petits producteurs pratiquant une
								agriculture raisonnée ou biologiques qui sont loin d'avoir été choisis par
								hasard. Leur sélection du terroir et leur travail très technique vous permettent
								d'obtenir des produits d'excellence, qui se dégustent de mi-Mars à début Juin
								en fonction de la météo."
							>
								Ce délice du printemps existe sous plusieurs variétés : asperge blanche,
								verte, paysanne, verte paysanne, verte fine, et sous plusieurs calibres&nbsp;:
								asperges fines, 10-16, 16-22, 22-24, +26, +28.
							</SectionText>
						</Parallax>
					</Column>
				</div>
			</section>
			<section className={styles.partie2}>
				<div className={styles.wrapperSection}>
					<Media query={media['>mobile']}>
						<div className={styles.wrapper}>
							<Parallax disabled={isTablet} y={['-80px', '100px']}>
								<SectionTitle titre1="LE PLUS" titre2="CRAQUANT" />
							</Parallax>

							<Column className={styles.img}>
								<Parallax disabled={isTablet} y={['-50px', '50px']}>
									<img src={radis} alt="" />
								</Parallax>
								<Vignette
									className={styles.vignetteRadis}
									// titre=""
									text="Installée depuis plus de 30
										ans dans le Val d'Oise, en
										Île-de-France, l'exploitation
										familiale de Francis
										Tremblay produit 
										localement des radis en
										agriculture raisonnée."
								/>
							</Column>
						</div>
						<Parallax disabled={isTablet} y={['80px', '-100px']}>
							<Column className={styles.text}>
								<SectionText
									intro="Le radis botte…"
									textPartOne="Le radis est connu pour sa richesse en vitamines et se déguste en saison de
									Mai à Octobre."
								>
									Pour notre approvisonnement, nous faisons confiance au savoir-faire de
									petits producteurs français et locaux, qui pratiquent une agriculture
									raisonnée ou biologique.
								</SectionText>
							</Column>

							<Column className={styles.img2}>
								<img src={radisCoupe} alt="" />
							</Column>
						</Parallax>
					</Media>
					<Media query={media['<=mobile']}>
						<Column className={styles.text}>
							<SectionTitle titre1="Ariga de" titre2="Champagne" />
							<SectionText
								intro="Le radis botte…"
								textPartOne="Le radis est connu pour sa richesse en vitamines et se déguste en saison de
									Mai à Octobre."
							>
								Pour notre approvisonnement, nous faisons confiance au savoir-faire de
								petits producteurs français et locaux, qui pratiquent une agriculture
								raisonnée ou biologique.
							</SectionText>
						</Column>
						<div className={styles.wrapper}>
							<Column className={styles.img}>
								<Parallax disabled={isTablet} y={['-50px', '50px']}>
									<img src={radis} alt="" />
								</Parallax>
							</Column>
						</div>
						<div className={styles.wrapper2}>
							<Vignette
								className={[styles.vignetteRadis, styles.vignette].join(' ')}
								// titre=""
								text="Installée depuis plus de 30
									ans dans le Val d'Oise, en
									Île-de-France, l'exploitation
									familiale de Francis
									Tremblay produit 
									localement des radis en
									agriculture raisonnée."
							/>
						</div>
						<div className={styles.wrapper3}>
							<img src={radisCoupe} alt="" />
						</div>
					</Media>
				</div>
			</section>

			<section
				className={[
					styles.partie3,
					clickSaison ? styles.deconstruct : styles.off,
				].join(' ')}
			>
				<div className={styles.wrapperSection}>
					<Media query={media['>mobile']}>
						<Column className={styles.img3}>
							<Parallax disabled={isTablet} y={['80px', '-100px']}>
								<img src={fraise} alt="" />
							</Parallax>
							<Parallax disabled={isTablet} y={['150px', '-100px']}>
								<Vignette
									className={styles.vignetteFraise}
									titre="Pulice"
									text="Pulice, un petit
										producteur d'Indre et Loire
										qui cultive les fraises avec
										soin et passion dans une
										logique de respect de la
										terre."
								/>
							</Parallax>
						</Column>

						<Column className={styles.text}>
							<Parallax disabled={isTablet} y={['-80px', '100px']}>
								<SectionTitle
									className={styles.titre}
									gauche
									titre1="Célébrité du"
									titre2="printemps"
								/>
								<Media query={media['>laptop']}>
									<SectionText
										intro="La fraise anaïs..."
										className={styles.text3Desktop}
										textPartOne="Parmi les nombreuses variétés que l'on peut trouver, la fraise anaïs est
										très appréciée pour ses qualités gustatives exceptionnelles. Elle est
										caractérisée par son arôme remarquable ainsi que son goût parfumé et
										sucré."
									>
										Nos fraises proviennent des exploitations de petits producteurs français
										et locaux, engagés pour l'environnement par la pratique d'une
										agriculture raisonnée.
									</SectionText>
								</Media>
							</Parallax>
						</Column>
					</Media>
					<Media query={media['<=mobile']}>
						<Column className={styles.text}>
							<Parallax disabled={isTablet} y={['-80px', '100px']}>
								<SectionTitle
									className={styles.titre}
									gauche
									titre1="Célébrité du"
									titre2="printemps"
								/>
								<SectionText
									intro="La fraise anaïs..."
									textPartOne="Parmi les nombreuses variétés que l'on peut trouver, la fraise anaïs est
									très appréciée pour ses qualités gustatives exceptionnelles. Elle est
									caractérisée par son arôme remarquable ainsi que son goût parfumé et
									sucré."
								>
									Nos fraises proviennent des exploitations de petits producteurs français
									et locaux, engagés pour l'environnement par la pratique d'une
									agriculture raisonnée.
								</SectionText>
							</Parallax>
						</Column>
						<Column className={styles.img3}>
							<Parallax disabled={isTablet} y={['80px', '-100px']}>
								<img src={fraise} alt="" />
							</Parallax>
							<Vignette
								className={styles.vignetteFraise}
								titre="Pulice"
								text="Pulice, un petit
									producteur d'Indre et Loire
									qui cultive les fraises avec
									soin et passion dans une
									logique de respect de la
									terre."
							/>
						</Column>
					</Media>
				</div>
				<Media query={media['<=laptop']}>
					<Media query={media['>mobile']}>
						<SectionText
							className={styles.text3}
							intro="La fraise anaïs..."
							textPartOne="Parmi les nombreuses variétés que l'on peut trouver, la fraise anaïs est
								très appréciée pour ses qualités gustatives exceptionnelles. Elle est
								caractérisée par son arôme remarquable ainsi que son goût parfumé et
								sucré."
						>
							Nos fraises proviennent des exploitations de petits producteurs français
							et locaux, engagés pour l'environnement par la pratique d'une
							agriculture raisonnée.
						</SectionText>
					</Media>
				</Media>
				<Footer></Footer>
			</section>
			<div
				className={[styles.saison, saison ? styles.center : styles.off].join(
					' ',
				)}
				onClick={retourHome}
			>
				<Saison clickSaison={clickSaison}></Saison>
			</div>
		</div>
	);
}

export default Printemps;
