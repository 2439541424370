import React from 'react';
import styles from './SectionTitle.module.scss';

export interface SectionTitleProps {
	className?: string;
	gauche?: boolean;
	titre1: string;
	titre2: string;
	titre3?: string;
}

function SectionTitle({
	className,
	gauche,
	titre1,
	titre2,
	titre3,
}: SectionTitleProps) {
	// console.log(gauche);
	return (
		<div
			className={[
				styles.wrapper,
				gauche ? styles.gauche : styles.droite,
				className,
			].join(' ')}
		>
			<span className={styles.titre}>
				{titre1}
				<br />
				{titre2}
				<br />
				{titre3}
			</span>
		</div>
	);
}

export default SectionTitle;
