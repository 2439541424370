import React, { createRef } from 'react';
import styles from './WebGL.module.scss';
import * as THREE from 'three';
import { Renderer } from './Renderer';
import { Component } from 'react';

import { ScenePrintemps } from './ScenePrintemps';
import { SceneEte } from './SceneEte';
import { SceneAutomne } from './SceneAutomne';
import { SceneHiver } from './SceneHiver';

import Transitions from './Transition';

interface WebGLProps {
	index: number;
	dragging: boolean;
	isRendering: boolean;
}

class WebGL extends Component<WebGLProps> {
	canvas = createRef<HTMLCanvasElement>();

	frameId = 0;
	fadingOut = false;
	fadingIn = false;
	windowWidth: any;

	renderer: Renderer | undefined;
	scene: THREE.Scene | undefined;
	transition: any;

	clock = new THREE.Clock();

	// Scenes Saisons
	scenes = [
		new SceneAutomne(''),
		new SceneHiver(''),
		new ScenePrintemps(''),
		new SceneEte(''),
	];

	start = () => {
		if (!this.frameId) this.frameId = requestAnimationFrame(this.animate);
	};

	stop = () => {
		cancelAnimationFrame(this.frameId);
		this.frameId = 0;
	};

	resize = () => {
		this.renderer!.resize();
		for (let i = 0; i < this.scenes.length; i++) this.scenes[i].resize();
	};

	animate = () => {
		this.frameId = requestAnimationFrame(this.animate);
		this.transition.render(this.renderer);
	};

	componentDidMount() {
		this.scene = this.scenes[this.props.index].scene;
		this.scene.position.z = 0;
		this.transition = new Transitions(
			this.scenes[this.props.index],
			this.scenes[this.props.index],
			0,
		);
		this.renderer = new Renderer({
			canvas: this.canvas.current!,
			scene: this.scene,
		});
		if (this.props.isRendering) this.start();

		window.addEventListener('resize', () => {
			if (this.windowWidth - 20 > window.innerWidth) this.resize();
			if (this.windowWidth < window.innerWidth) this.resize();
			this.windowWidth = window.innerWidth;
		});

		window.addEventListener('scroll', this.shouldStop);
	}

	shouldStop = () => {
		if (window.scrollY >= 285) {
			this.stop();
		}

		if (window.scrollY <= 265 && !this.frameId && this.fadingOut) {
			this.start();
		}
	};

	transitionHeader = (color: string) => {
		setTimeout(() => {
			let svg = document.querySelector('#logo-header');
			svg!.children[1].setAttribute('fill', color);
			svg!.children[2].setAttribute('stroke', color);
			svg!.children[3].setAttribute('stroke', color);
			svg!.children[4].setAttribute('stroke', color);
			svg!.children[5].setAttribute('fill', color);
			svg!.children[6].setAttribute('fill', color);
			let burgerLines = document.getElementsByClassName(
				'line',
			) as HTMLCollectionOf<HTMLElement>;
			for (let i = 0; i < burgerLines.length; i++) {
				burgerLines[i].style.background = color;
			}
		}, 400);
	};

	componentDidUpdate(prevProps: WebGLProps) {
		if (!prevProps.isRendering && this.props.isRendering && !this.fadingOut) {
			// si on arrive d'une page à la Home
			// on démarre le webgl et on fadeIn
			this.fadingIn = true;
			this.start();
			this.transitionHeader('white');
			this.scenes[this.props.index]!.fade('in');

			setTimeout(() => {
				this.fadingIn = false;
			}, 900);
		} else if (
			prevProps.isRendering &&
			!this.props.isRendering &&
			!this.fadingIn
		) {
			// si on arrive de la Home à une autre page
			// on fadeOut, et on stop à la fin du fadeOut
			this.transitionHeader('#e72a7e');
			this.fadingOut = true;
			this.scenes[this.props.index]!.fade('out');
			setTimeout(() => {
				this.fadingOut = false;
				this.stop();
			}, 900);
		} else {
			// sinon, c'est qu'on navigue dans le carousel
			if (!this.fadingOut && !this.fadingIn) {
				let index;
				switch (this.scene!.name) {
					case 'automne':
						index = 0;
						break;
					case 'hiver':
						index = 1;
						break;
					case 'printemps':
						index = 2;
						break;
					case 'ete':
						index = 3;
						break;
					default:
						index = this.props.index;
						break;
				}

				var intensityTransition = 0.5;
				if (this.props.index < index) {
					intensityTransition = -0.5;
				}
				if (this.props.index === -0 && index === 3) {
					intensityTransition = 0.5;
				}
				if (this.props.index === 3 && index === 0) {
					intensityTransition = -0.5;
				}

				let sceneA = this.scenes[index];
				let sceneB = this.scenes[this.props.index];
				this.scene = this.scenes[this.props.index].scene;
				if (sceneA.scene.name !== sceneB.scene.name) {
					this.transition = new Transitions(
						this.scenes[index],
						this.scenes[this.props.index],
						intensityTransition,
					);
					this.transition.render(this.renderer);
				}
				for (let i = 0; i < this.scenes.length; i++) {
					this.scenes[i].setActive(false);
				}
				this.scenes[this.props.index].setActive(true);
			}
		}
	}

	componentWillUnmount() {
		this.stop();
		this.renderer!.destroy();
		window.removeEventListener('resize', this.resize);
		window.removeEventListener('scroll', this.shouldStop);
	}

	render() {
		return (
			<canvas
				ref={this.canvas}
				className={[styles.canvas, this.props.dragging && styles.zoomedOut]
					.filter(Boolean)
					.join(' ')}
			/>
		);
	}
}

export default WebGL;

// https://tympanus.net/Development/DraggableImageStrip/
