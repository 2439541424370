import React from 'react';
import styles from './Burger.module.scss';

interface BurgerProps {
	isOpen: boolean;
	isHidden: boolean;
	onClick: () => void;
}

function Burger({ isOpen, isHidden, onClick }: BurgerProps) {
	return (
		<button>
			<div
				onClick={onClick}
				className={[
					styles.wrapper,
					isOpen && styles.isOpen,
					isHidden && styles.isHidden,
				]
					.filter(Boolean)
					.join(' ')}
			>
				<div className={[styles.line, 'line'].join(' ')} />
				<div className={[styles.line, 'line'].join(' ')} />
				<div className={[styles.line, 'line'].join(' ')} />
			</div>
		</button>
	);
}

export default Burger;
