// Mp4
import hiver_mp4_320x240 from './hiver-320x240.mp4';
import hiver_mp4_640x480 from './hiver-640x480.mp4';
import hiver_mp4_1280x720 from './hiver-1280x720.mp4';
import hiver_mp4_1920x1080 from './hiver-1920x1080.mp4';

// Webm
import hiver_webm_320x240 from './hiver-320x240.webm';
import hiver_webm_640x480 from './hiver-640x480.webm';
import hiver_webm_1280x720 from './hiver-1280x720.webm';
import hiver_webm_1920x1080 from './hiver-1920x1080.webm';

export default {
	mp4: {
		'320x240': hiver_mp4_320x240,
		'640x480': hiver_mp4_640x480,
		'1280x720': hiver_mp4_1280x720,
		'1920x1080': hiver_mp4_1920x1080,
	},
	webm: {
		'320x240': hiver_webm_320x240,
		'640x480': hiver_webm_640x480,
		'1280x720': hiver_webm_1280x720,
		'1920x1080': hiver_webm_1920x1080,
	},
} as VideoSources;
