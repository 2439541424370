import * as THREE from 'three';
import gsap from 'gsap';
import macro from 'raw.macro';

import displaceImage from './textures/transition.png';
const vertexShader = macro('./shaders/vertexShader.glsl');
const fragmentShader = macro('./shaders/fragmentShader.glsl');

class Transitions {
	private scene: THREE.Scene;
	private sceneA: any;
	private sceneB: any;
	private transitionOver: boolean = false;
	private tween: GSAPStatic.Tween | undefined;

	private camera: THREE.OrthographicCamera;

	private transitionMesh: THREE.Mesh;

	private transitionMaterial: any;
	private transitionParams = {
		transition: 0,
		transitionSpeed: 7.0,
		duration: 0.9,
	};

	constructor(sceneA: any, sceneB: any, intensity: number) {
		this.scene = new THREE.Scene();
		this.transitionOver = false;
		this.camera = new THREE.OrthographicCamera(
			window.innerWidth / -2,
			window.innerWidth / 2,
			window.innerHeight / 2,
			window.innerHeight / -2,
			-10,
			10,
		);

		const displaceTexture = new THREE.TextureLoader().load(displaceImage);
		displaceTexture.minFilter = THREE.LinearFilter;

		let uniform = {
			fbo1: { type: 't', value: null },
			fbo2: { type: 't', value: null },
			intensity: { type: 'f', value: intensity },
			mixFactor: { type: 'f', value: this.transitionParams.transition },
			vertical: { type: 'b', value: false },
			displaceTexture: { type: 't', value: displaceTexture },
		};

		this.transitionMaterial = new THREE.ShaderMaterial({
			uniforms: uniform,
			vertexShader: vertexShader,
			fragmentShader: fragmentShader,
			transparent: true,
		});

		const transitionGeo = new THREE.PlaneGeometry(
			window.innerWidth,
			window.innerHeight,
		);

		this.transitionMesh = new THREE.Mesh(
			transitionGeo,
			this.transitionMaterial,
		);
		this.scene.add(this.transitionMesh);

		this.sceneA = sceneA;
		this.sceneB = sceneB;

		this.transitionMaterial.uniforms.fbo1.value = sceneA.fbo.texture;
		this.transitionMaterial.uniforms.fbo2.value = sceneB.fbo.texture;

		//console.log(sceneA.scene.name + '->' + sceneB.scene.name);
	}

	private transitTo() {
		if (!this.tween) {
			this.tween = gsap.to(
				this.transitionMaterial.uniforms.mixFactor,
				this.transitionParams.duration,
				{
					value: 1,
					ease: 'power4',
					onComplete: () => {
						this.transitionOver = true;
					},
				},
			);
		}
	}

	public render(renderer: any) {
		if (this.transitionOver) {
			this.sceneB.renderScene(renderer, false);
			return;
		}
		this.transitTo();

		let param = {
			scene: this.scene,
			camera: this.camera,
			fbo: null,
			clear: true,
		};

		this.sceneA.renderScene(renderer, true);
		this.sceneB.renderScene(renderer, true);
		renderer.render(param);
	}
}

export default Transitions;
