import React, { useState } from 'react';
import styles from './Produits.module.scss';
import Column from '../../components/Column/Column';
import jan from './img-saison/jan.jpg';
import fev from './img-saison/fev.jpg';
import mar from './img-saison/mar.jpg';
import avr from './img-saison/avr.jpg';
import mai from './img-saison/mai.jpg';
import juin from './img-saison/juin.jpg';
import juil from './img-saison/juil.jpg';
import aou from './img-saison/aou.jpg';
import sep from './img-saison/sep.jpg';
import oct from './img-saison/oct.jpg';
import nov from './img-saison/nov.jpg';
import dec from './img-saison/dec.jpg';
import SmoothScrolling from './smoothScrolling';
import json from './seasons.json';
import Head from '../../components/Head/Head';

const img = [jan, fev, mar, avr, mai, juin, juil, aou, sep, oct, nov, dec];

const roue = {
	month: [
		'Janvier',
		'Février',
		'Mars',
		'Avril',
		'Mai',
		'Juin',
		'Juillet',
		'Août',
		'Septembre',
		'Octobre',
		'Novembre',
		'Décembre',
	],
	short: [
		'jan',
		'fev',
		'mar',
		'avr',
		'mai',
		'juin',
		'juil',
		'aou',
		'sep',
		'oct',
		'nov',
		'dec',
	],
};

function Produits() {
	const [month, setMonth] = useState('Jan');
	const [selectedMonth, setselectedMonth] = useState('Jan');
	const [content, setContent] = useState([json[0]]);

	function changeMonth(el: string, i: number) {
		setMonth(el);
		setselectedMonth(el);
		setContent([json[i]]);
		SmoothScrolling.scrollTo('test');
	}

	function changeImg(el: string) {
		setMonth(el);
	}

	function resetImg() {
		setMonth(selectedMonth);
	}

	return (
		<Column className={styles.saison}>
			<Head title="Nos produits" description="" />

			<div className={styles.roue_wrapper}>
				<div id="roue" className={styles.roue}>
					{roue.short.map((mois, i) => (
						<label
							key={i}
							onMouseOver={() => {
								changeImg(mois);
							}}
							onMouseLeave={() => {
								resetImg();
							}}
						>
							<input
								type="radio"
								name="month"
								value={i}
								onClick={() => {
									changeMonth(mois, i);
								}}
							// checked={mois === 'Jan' && true}
							/>
							<span></span>
							<p className={styles.mois}>{mois}</p>
						</label>
					))}

					<figure>
						{img.map((img, i) => (
							<img
								key={i}
								alt=""
								src={img}
								className={`${img.includes(month) && styles.on}`}
							/>
						))}
						{img.map((img, i) => (
							<p key={i} className={`${img.includes(month) && styles.textOn}`}>
								{roue.month[i]}
							</p>
						))}
					</figure>
				</div>
			</div>
			{content.map((item, i) => (
				<div key={i} className={styles.texte}>
					<p id="test" className={styles.saison}>
						{item.title}
					</p>

					<div className={styles.wrapper}>
						<div className={styles.section}>
							<p className={styles.titre}>Légumes et fruits français</p>
							<div className={styles.francais}>
								{item.france.map((el, i) => (
									<div key={i} className={styles.part}>
										<p className={styles.title}>{el.title}</p>
										<p className={styles.content}>{el.content}</p>
									</div>
								))}
							</div>
							{item.vedette[0] && (
								<div className={styles.vedette}>
									{item.vedette.map((el, i) => (
										<div key={i} className={styles.part}>
											<p className={styles.title}>{el.title}</p>
											<p className={styles.content}>{el.content}</p>
										</div>
									))}
								</div>
							)}
							{item.fin[0] && (
								<div className={styles.fin}>
									{item.fin.map((el, i) => (
										<div key={i}>
											<p className={styles.title}>{el.title}</p>
											<p className={styles.content}>{el.content}</p>
										</div>
									))}
								</div>
							)}
						</div>
						<div className={styles.section}>
							<p className={styles.titre}>Produits du monde</p>
							<div className={styles.monde}>
								{item.monde.map((el, i) => (
									<div key={i} className={styles.part}>
										<p className={styles.title}>{el.title}</p>
										<p className={styles.content}>{el.content}</p>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			))}
		</Column>
	);
}

export default Produits;
