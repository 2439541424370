// Mp4
import printemps_mp4_320x240 from './printemps-320x240.mp4';
import printemps_mp4_640x480 from './printemps-640x480.mp4';
import printemps_mp4_1280x720 from './printemps-1280x720.mp4';
import printemps_mp4_1920x1080 from './printemps-1920x1080.mp4';

// Webm
import printemps_webm_320x240 from './printemps-320x240.webm';
import printemps_webm_640x480 from './printemps-640x480.webm';
import printemps_webm_1280x720 from './printemps-1280x720.webm';
import printemps_webm_1920x1080 from './printemps-1920x1080.webm';

export default {
	mp4: {
		'320x240': printemps_mp4_320x240,
		'640x480': printemps_mp4_640x480,
		'1280x720': printemps_mp4_1280x720,
		'1920x1080': printemps_mp4_1920x1080,
	},
	webm: {
		'320x240': printemps_webm_320x240,
		'640x480': printemps_webm_640x480,
		'1280x720': printemps_webm_1280x720,
		'1920x1080': printemps_webm_1920x1080,
	},
} as VideoSources;
