import React from 'react';
import styles from './Column.module.scss';

export interface ColumnProps {
	className?: string;
	children?: any;
}

function Column({ children, className }: ColumnProps) {
	return (
		<div className={[styles.col, className].filter(c => c).join(' ')}>
			{children}
		</div>
	);
}

export default Column;
