import React from 'react';
import styles from './Footer.module.scss';
import { Link } from 'react-router-dom';

function Footer() {
	return (
		<div className={styles.wrapper}>
			<p>
				« Découvrez ce que chaque saison <br />
				nous offre de meilleur »
			</p>
			<Link to="/nos-produits">CALENDRIER DES SAISONS</Link>
		</div>
	);
}

export default Footer;
