import * as THREE from 'three';
import gsap from 'gsap';
import Scenes from './Scenes';
import { Camera } from './Camera';

export class SceneAutomne extends Scenes {
	public scene: THREE.Scene;
	public camera: any;
	public fbo: any;
	private size = { width: 1620 };
	private posX: number = 0;
	private posY: number = 0;
	private tweens: any = [];
	private bgMesh: any;
	private bgMaskMesh: any;
	private textUp: boolean = false;
	private timer: any;
	private init: boolean = true;
	private isActive: boolean = false;

	sceneData = [
		{
			0: 'AUT',
			1: 'O',
			2: 'MN',
			3: 'E',
		},
		{
			0: require('../../data/img/automne/pomme-gauche.png'),
			1: require('../../data/img/automne/pomme-droite.png'),
			2: require('../../data/img/automne/pomme-groupe.png'),
		},
	];

	constructor(props: any) {
		super(props);
		this.scene = new THREE.Scene();
		this.camera = new Camera();
		this.scene.name = 'automne';

		const backgrounds = this.createBackgrounds(0xefbc41);
		this.bgMesh = backgrounds[0];
		this.bgMaskMesh = backgrounds[1];
		this.scene.add(this.bgMesh, this.bgMaskMesh);

		let arr = Object.values(this.sceneData[1]);
		this.createImages(arr, this.scene);

		window.addEventListener('mousemove', e => {
			this.posX = e.clientX;
			this.posY = e.clientY;

			window.requestAnimationFrame(() => {
				if (this.isActive) this.animateText();
			});
		});

		this.fbo = new THREE.WebGLRenderTarget(
			window.innerWidth,
			window.innerHeight,
			{
				minFilter: THREE.LinearFilter,
				magFilter: THREE.LinearFilter,
				format: THREE.RGBFormat,
				stencilBuffer: false,
			},
		);
		this.resize();
	}

	private animateText() {
		if (!this.scene.children[8] || !this.textUp) return;

		let proportion = window.innerWidth;
		if (window.innerWidth < 500) proportion = window.innerWidth * 1.2;

		let sprites = [
			this.scene.children[5],
			this.scene.children[6],
			this.scene.children[7],
			this.scene.children[8],
		];

		var posX = this.posX / 25 - (proportion / 50) * 1.5;
		var posY = this.posY / 25 - (window.innerHeight / 50) * 1.5;
		let pos = [
			-(proportion / 5.5),
			-(proportion / 45),
			proportion / 8.8,
			proportion / 4.25,
		];

		for (let i = 0; i < sprites.length; i++) {
			if (this.tweens[i]) this.tweens[i].kill();
			this.tweens[i] = gsap.to(sprites[i].position, 0.5, {
				x: -posX + pos[i],
				y: posY,
				ease: 'slow(0.1, 0.7, false)',
			});
		}
	}

	public setActive(isActive: boolean) {
		this.isActive = isActive;
		this.scene.dispose();
	}

	private getText() {
		if (this.scene.children[7]) return;
		let arr = Object.values(this.sceneData[0]);
		this.createTexts(arr, this.scene);

		setTimeout(() => {
			this.textUp = true;
		}, 500);
	}

	public setText(delay: number) {
		this.textUp = false;
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			this.getText();
		}, delay);
	}

	public async fade(direction: 'in' | 'out') {
		this.animateBackground(direction, this.bgMesh, this.bgMaskMesh, this.scene);
	}

	public async resize() {
		await this.resizeScene(this.scene, this.size.width);

		if (this.scene.children.length === 7) {
			this.scene.remove(this.scene.children[5], this.scene.children[6]);
		}
		this.init ? this.setText(0) : this.setText(1000);
		this.camera.resize();
		if (this.init) this.init = false;
	}

	public renderScene(renderer: any, rtt: any) {
		if (rtt) {
			let param = {
				scene: this.scene,
				camera: this.camera.nativeCamera,
				fbo: this.fbo,
				clear: true,
			};
			renderer.render(param);
		} else {
			let param = {
				scene: this.scene,
				camera: this.camera.nativeCamera,
				fbo: null,
				clear: true,
			};
			renderer.render(param);
		}
	}
}
