import Produits from '../pages/Produits/Produits';
import Contact from '../pages/Contact/Contact';
import Printemps from '../pages/Printemps/Printemps';
import Ete from '../pages/Ete/Ete';
import Automne from '../pages/Automne/Automne';
import Hiver from '../pages/Hiver/Hiver';
import Histoire from '../pages/Histoire/Histoire';
import MentionLegal from '../pages/MentionLegal/MentionLegal';

export const routes = [
	{ path: '/nos-produits', Component: Produits },
	{ path: '/notre-histoire', Component: Histoire },
	{ path: '/contact', Component: Contact },
	{ path: '/mentions-legales', Component: MentionLegal },
	{ path: '/printemps', Component: Printemps },
	{ path: '/ete', Component: Ete },
	{ path: '/automne', Component: Automne },
	{ path: '/hiver', Component: Hiver },
];
