import React, { Component } from 'react';
import styles from './CarouselSaisons.module.scss';
import gsap from 'gsap';
import { isTouchEvent } from '../../utils';
import { saisons } from '../../data/saisons';

function calcImageWidth() {
	const factor =
		window.innerWidth > 768
			? +styles.slideWidthFrom769
			: +styles.slideWidthUntil768;
	return (window.innerWidth * factor) / 100;
}

interface CarouselSaisonsProps {
	index: number;
	dragging: boolean;
	onChange: (index: number) => void;
	setDragging: (dragging: boolean) => void;
}

class CarouselSaisons extends Component<CarouselSaisonsProps> {
	/** Référence du carousel dans le DOM */
	carousel = React.createRef<HTMLUListElement>();
	/** Instance de GSAP qui sert au pointerUp */
	momentumTween: GSAPStatic.Tween | null = null;
	/** Définit si le pointer est down ou pas */
	dragging = false;
	/** Distance totale parcourue entre le pointerDown et maintenant */
	totalDist = 0;
	/** Index du slide le plus au centre de l'écran */
	currentSlide = -1;
	/** Durée d'amorti (en secondes) */
	duration = 0.8;
	/** min distance before swap slide */
	minDist = window.innerWidth / 10;
	/** Largeur en pixels de l'image */
	imageTotalWidth = calcImageWidth();
	lastPos = { x: 0 };
	carouselPos = { x: 0 };
	dragPos = { x: 0 };
	lastDragPos = { x: 0 };

	prev = () => {
		let index = this.props.index;
		if (index === 0) index = 4;
		this.setGalleryPos(Math.max(0, index - 1));
	};

	next = () => {
		let index = this.props.index;
		if (index === 3) index = -1;
		this.setGalleryPos(Math.min(saisons.length - 1, index + 1));
	};

	onSaisonClick(i: number) {
		// Le 10 est la limite de move tolérée pour un click
		if (Math.abs(this.totalDist) < 10) this.setGalleryPos(i);
	}

	onPointerDown = (e: MouseEvent | TouchEvent) => {
		const { pageX } = isTouchEvent(e) ? e.touches[0] : e;
		this.dragging = true;
		this.dragPos.x = this.lastDragPos.x = pageX;
		this.totalDist = 0;
		//this.props.setDragging(true);
	};

	onPointerMove = (e: MouseEvent | TouchEvent) => {
		if (!this.dragging) return;
		this.dragPos.x = isTouchEvent(e) ? e.touches[0].pageX : e.pageX;
	};

	onPointerUp = () => {
		if (!this.dragging) return;
		this.dragging = false;
		if (this.lastDragPos.x - this.minDist > this.dragPos.x) {
			this.next();
		}

		if (this.lastDragPos.x + this.minDist < this.dragPos.x) {
			this.prev();
		}

		//this.props.setDragging(false);
	};

	/** Déplace le carousel et met à jour l'index du slide actif */
	updateCarouselPos = () => {
		if (!this.carousel.current) return;
		gsap.set(this.carousel.current, {
			x: this.carouselPos.x + (window.innerWidth - this.imageTotalWidth) / 2,
			lazy: true,
		});
		this.lastPos.x = this.carouselPos.x;
		const _currentSlide = Math.round(
			-this.carouselPos.x / this.imageTotalWidth,
		);
		if (_currentSlide !== this.currentSlide) {
			this.currentSlide = _currentSlide;
			if (_currentSlide >= 0 && _currentSlide < saisons.length) {
				this.props.onChange(_currentSlide);
			}
		}
	};

	/** Permet de réinitialiser l'instance de gsap qui sert au pointerUp */
	stopMomentum() {
		if (!this.momentumTween) return;
		this.momentumTween.kill();
		this.momentumTween = null;
	}

	/**
	 * Sert à aller direct à un index défini.
	 * Est utilisé à l'initialisation et au clic sur un slide.
	 * @param index - Index du slide auquel aller
	 * @param isAnimated - Permet d'aller à un slide sans l'animer
	 */
	setGalleryPos(index: number, isAnimated = false) {
		this.stopMomentum();
		this.momentumTween = gsap.to(this.carouselPos, {
			duration: isAnimated ? this.duration : 0,
			x: -index * this.imageTotalWidth,
			ease: 'power4',
			onUpdate: this.updateCarouselPos,
			onComplete: this.updateCarouselPos,
		});
	}

	onResize = () => {
		this.imageTotalWidth = calcImageWidth();
		this.setGalleryPos(this.currentSlide, false);
	};

	onKeyDown = (e: KeyboardEvent) => {
		if (e.key === 'ArrowRight') this.next();
		else if (e.key === 'ArrowLeft') this.prev();
	};

	componentDidMount() {
		this.setGalleryPos(this.props.index, false);
		window.addEventListener('mousemove', this.onPointerMove);
		window.addEventListener('touchmove', this.onPointerMove);
		window.addEventListener('mouseup', this.onPointerUp);
		window.addEventListener('touchend', this.onPointerUp);
		window.addEventListener('resize', this.onResize);
		window.addEventListener('keydown', this.onKeyDown);
	}

	componentWillUnmount() {
		window.removeEventListener('mousemove', this.onPointerMove);
		window.removeEventListener('touchmove', this.onPointerMove);
		window.removeEventListener('mouseup', this.onPointerUp);
		window.removeEventListener('touchend', this.onPointerUp);
		window.removeEventListener('resize', this.onResize);
		window.removeEventListener('keydown', this.onKeyDown);
	}

	render() {
		return (
			<div
				data-cursor-drag
				onMouseDown={e => this.onPointerDown(e.nativeEvent)}
				onTouchStart={e => this.onPointerDown(e.nativeEvent)}
				className={[styles.wrapper, this.props.dragging && styles.dragging]
					.filter(Boolean)
					.join(' ')}
			>
				<button
					onClick={this.prev}
					className={[styles.prev, this.props.index === 0]
						.filter(Boolean)
						.join(' ')}
				/>

				<ul ref={this.carousel} className={styles.saisons + ' homeCarousel'}>
					{saisons.map(({ title, slug }, i) => (
						<li
							key={i}
							onClick={() => this.onSaisonClick(i)}
							className={styles.saison + ' homeCarouselSlide'}
						>
							<a
								href={'/' + slug}
								onClick={e => e.preventDefault()}
								className={[
									styles.title,
									this.props.index === i && styles.active,
									'homeCarouselTitle',
								]
									.filter(Boolean)
									.join(' ')}
							>
								{title.split('').map((letter, u) => (
									<span key={u}>{letter}</span>
								))}
							</a>
						</li>
					))}
				</ul>

				<button
					onClick={this.next}
					className={[styles.next, this.props.index === saisons.length - 1]
						.filter(Boolean)
						.join(' ')}
				/>
			</div>
		);
	}
}

export default CarouselSaisons;
