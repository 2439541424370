import React from 'react';
import styles from './Vignette.module.scss';

export interface VignetteProps {
	className?: string;
	img?: string;
	titre?: string;
	text: string;
}

function Vignette({ className, img, titre, text }: VignetteProps) {
	return (
		<div className={[styles.wrapper, className].filter(c => c).join(' ')}>
			{img &&
				<img src={img} className={styles.imgCorse} alt="corse" />
			}
			{titre && <span>{titre}</span>}
			<p>{text}</p>
		</div>
	);
}

export default Vignette;
