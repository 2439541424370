import { Component } from 'react';
import * as THREE from 'three';
import gsap from 'gsap';

const FontFaceObserver = require('fontfaceobserver');

class Scenes extends Component {
	private font: any;
	private tweensMask: any = [];

	constructor(props: any) {
		super(props);

		this.font = new FontFaceObserver('gilroy', {
			weight: 700,
		});
	}

	createTexts = (arrText: any, scene: THREE.Scene) => {
		let proportion = window.innerWidth;
		if (window.innerWidth < 500) proportion = window.innerWidth * 1.2;
		let pixel = proportion / 8.23;

		this.font.load().then(() => {
			for (let i = 0; i < arrText.length; i++) {
				var canvas = document.createElement('canvas');
				canvas.width = pixel * 4;
				canvas.height = pixel * 2;
				var context = canvas.getContext('2d');
				context!.font = pixel + 'px gilroy';

				var metrics = context!.measureText(arrText[i]);
				var textWidth = metrics.width;
				context!.fillStyle = 'white';
				context!.textAlign = 'left';
				context!.textBaseline = 'middle';
				context!.shadowColor = 'rgba(0,0,0,0.1)';
				context!.shadowBlur = 20;
				context!.shadowOffsetX = 20;
				context!.shadowOffsetY = 30;
				context!.fillText(arrText[i], (canvas.width - textWidth) / 2, pixel);

				var texture = new THREE.Texture(canvas);
				texture.magFilter = THREE.NearestFilter;
				texture.minFilter = THREE.NearestFilter;
				texture.needsUpdate = true;

				var spriteMaterial = new THREE.SpriteMaterial({ map: texture });
				var sprite = new THREE.Sprite(spriteMaterial);
				sprite.scale.set(canvas.width, canvas.height, 1);

				if (scene.name === 'ete') {
					// ET
					if (i === 0) {
						sprite.position.x = -(proportion / 21);
						sprite.position.z = -2;
					}
					// E
					if (i === 1) {
						sprite.position.x = proportion / 22;
						sprite.position.z = -2;
					}
					sprite.position.y = 0;
				}

				if (scene.name === 'printemps') {
					// PRI
					if (i === 0) {
						sprite.position.x = -(proportion / 4.5);
						sprite.position.z = -6;
					}
					// NT
					if (i === 1) {
						sprite.position.x = -(proportion / 16);
						sprite.position.z = -3;
					}
					// E
					if (i === 2) {
						sprite.position.x = proportion / 24;
						sprite.position.z = -1;
					}
					// MPS
					if (i === 3) {
						sprite.position.x = proportion / 5.2;
						sprite.position.z = -8;
					}
					sprite.position.y = -30;
				}

				if (scene.name === 'automne') {
					// AUT
					if (i === 0) {
						sprite.position.x = -(proportion / 5.5);
						sprite.position.z = -1;
					}
					// O
					if (i === 1) {
						sprite.position.x = -(proportion / 45);
						sprite.position.z = -2;
					}
					// MN
					if (i === 2) {
						sprite.position.x = proportion / 8.8;
						sprite.position.z = -1;
					}
					// E
					if (i === 3) {
						sprite.position.x = proportion / 4.25;
						sprite.position.z = -3;
					}
					sprite.position.y = 0;
				}

				if (scene.name === 'hiver') {
					// HI
					if (i === 0) {
						sprite.position.x = -(proportion / 10);
						sprite.position.z = -1;
					}
					// VER
					if (i === 1) {
						sprite.position.x = proportion / 15;
						sprite.position.z = -4;
					}
					sprite.position.y = -10;
				}

				sprite.name = 'text';
				scene.add(sprite);
			}
		});
	};

	createImages = (arrImg: any, scene: THREE.Scene) => {
		let textures = [];
		for (let i = 0; i < arrImg.length; i++) {
			let texture = new THREE.TextureLoader().load(arrImg[i] as any);
			texture.minFilter = THREE.LinearFilter;
			textures.push(texture);
		}

		let geometry = new THREE.PlaneBufferGeometry(1, 1, 1);
		for (let i = 0; i < textures.length; i++) {
			let material = new THREE.MeshBasicMaterial({
				map: textures[i],
				side: THREE.DoubleSide,
				transparent: true,
			});
			let mesh = new THREE.Mesh(geometry, material);

			if (scene.name === 'ete') {
				if (i === 0) mesh.position.z = -1;
				if (i === 1) mesh.position.z = -2;
				if (i === 2) mesh.position.z = -5;
				mesh.position.y = -3;
			}
			if (scene.name === 'printemps') {
				if (i === 0) mesh.position.z = -4;
				if (i === 1) mesh.position.z = -2;
				if (i === 2) mesh.position.z = -7;
				if (i === 3) mesh.position.z = -10;
			}
			if (scene.name === 'automne') {
				if (i === 0) mesh.position.z = -3;
				if (i === 1) mesh.position.z = -1;
				if (i === 2) mesh.position.z = -7;
				mesh.position.y = -5;
			}

			if (scene.name === 'hiver') {
				if (i === 0) mesh.position.z = -3;
				if (i === 1) mesh.position.z = -4;
			}
			scene.add(mesh);
		}
	};

	createBackgrounds = (color: any) => {
		let bgGeo = new THREE.CircleBufferGeometry(1, 100, 1);
		let bgMat = new THREE.MeshBasicMaterial({ color: color });
		const bgMesh = new THREE.Mesh(bgGeo, bgMat);
		bgMesh.position.z = -12;
		bgMesh.name = 'mask';

		let bgMaskGeo = new THREE.CircleBufferGeometry(1, 100, 1);
		let bgMaskMat = new THREE.MeshBasicMaterial({ color: 0xffffff });
		const bgMaskMesh = new THREE.Mesh(bgMaskGeo, bgMaskMat);
		bgMaskMesh.position.z = -11;
		bgMaskMesh.position.y = -(window.innerHeight * 2) / 1.3;
		bgMaskMesh.name = 'mask';

		return [bgMesh, bgMaskMesh];
	};

	animateBackground = (
		direction: 'in' | 'out',
		bgMesh: THREE.Mesh,
		bgMaskMesh: THREE.Mesh,
		scene: THREE.Scene,
	) => {
		let y = 950;
		let y2 = 1000;
		let duration = 0.1;
		let durUp = 8;
		let durDown = 8;
		bgMaskMesh.position.z = -11;

		// mobile
		if (window.innerWidth < 550) {
			durUp = 8;
			durDown = 8;
			bgMaskMesh.scale.x = window.innerWidth * 3;
			bgMaskMesh.scale.y = window.innerWidth * 3;
			y = 550;
		}

		if (direction === 'in') {
			y = 0;
			y2 = -1500;
			durUp = 2;
			durDown = 0;
			duration = 0.3;
			bgMaskMesh.position.z = -13;
			bgMesh.position.y = 1000;
			scene.position.y = 950;
		}

		if (this.tweensMask[0]) this.tweensMask[0].kill();
		this.tweensMask[0] = gsap.to(scene.position, 1.5, {
			y: y,
			ease: 'power4.out',
			delay: duration,
		});

		// Mask orange
		if (this.tweensMask[1]) this.tweensMask[1].kill();
		this.tweensMask[1] = gsap.to(bgMesh.position, durUp, {
			y: y,
			ease: 'power4.out',
			delay: duration,
		});

		// Mask blanc
		if (this.tweensMask[2]) this.tweensMask[2].kill();
		if (direction === 'out') {
			this.tweensMask[2] = gsap.to(bgMaskMesh.position, durDown, {
				y: y2,
				ease: 'power4.out',
				delay: duration,
			});
		} else {
			bgMaskMesh.position.y = y2;
		}
	};

	async resizeScene(scene: THREE.Scene, size: number) {
		var planeWidth = Math.min(window.innerWidth, size);
		var planeHeight = (planeWidth * 10) / 16;

		if (window.innerWidth < 500) {
			planeWidth = planeWidth * 1.2;
			planeHeight = planeHeight * 1.2;
		}

		for (let i = 0; i < scene.children.length; i++) {
			if (scene.children[i].name === 'mask') {
				scene.children[i].scale.x = window.innerWidth;
				scene.children[i].scale.y = window.innerHeight;
			} else if (scene.children[i].name === 'text') {
				scene.remove(scene.children[i]);
			} else {
				scene.children[i].scale.x = planeWidth;
				scene.children[i].scale.y = planeHeight;
			}

			if (i === scene.children.length) {
				return true;
			}
		}
	}
}

export default Scenes;
