import React from 'react';
import styles from './Home.module.scss';
import Head from '../../components/Head/Head';
import CarouselSaisons from '../../components/CarouselSaisons/CarouselSaisons';
import CallToActionHome from '../../components/CallToActionHome/CallToActionHome';
import { saisons } from '../../data/saisons';
import metaImg from './automne.jpg';

interface HomeProps {
	index: number;
	setIndex: (index: number) => void;
	dragging: boolean;
	setDragging: (dragging: boolean) => void;
}

function Home({ index, setIndex, dragging, setDragging }: HomeProps) {
	return (
		<main className={styles.wrapper}>
			<Head title="Accueil" description="" img={metaImg} />

			<CarouselSaisons
				index={index}
				dragging={dragging}
				setDragging={setDragging}
				onChange={index => setIndex(index)}
			/>

			<CallToActionHome href={'/' + saisons[index].slug} />
		</main>
	);
}

export default Home;
