import React, { Component } from 'react';
import { withRouter, RouteComponentProps, Redirect } from 'react-router';
import { ParallaxProvider } from 'react-scroll-parallax';
import Mouse from '../components/Mouse/Mouse';
import Header from '../components/Header/Header';
import dateformat from 'dateformat';
import Home from '../pages/Home/Home';
import WebGL from '../components/WebGL/WebGL';
import { saisons } from '../data/saisons';
// import MediaQuery from 'react-responsive';
// import { media } from '../utils';
import AnimatedRoute from '../components/AnimatedRoute/AnimatedRoute';
import { routes } from './routes';

import Intro from '../components/Intro/Intro';

const touchLimit = 100;

interface HomeState {
	index: number;
	dragging: boolean;
}

class App extends Component<RouteComponentProps, HomeState> {
	touchStart = 0;
	animated = false;
	init = true;
	prevIndex = 0;
	html = document.querySelector('html');
	isMobile = window.innerWidth < 525;

	constructor(props: RouteComponentProps) {
		super(props);

		// On change la saison en fonction de la date
		const today = +dateformat(new Date(), 'mdd');
		let index = saisons.findIndex((s) => s.isNow(today));

		this.state = { index, dragging: false };
	}

	goDown() {
		if (!this.animated) {
			if (window.scrollY === 0 && this.props.location.pathname === '/') {
				this.props.history.push('/' + saisons[this.state.index].slug);
				this.setAnimate();
			} else {
				document.body.style.overflowY = 'overlay';
				if (this.isMobile) this.html!.style.overflow = 'auto';
			}
		}
	}

	goUp() {
		if (!this.animated) {
			if (
				window.scrollY === 0 &&
				this.props.location.pathname !== '/' &&
				!['/nos-produits', '/contact'].includes(this.props.location.pathname)
			) {
				this.props.history.push('/');
				document.body.style.overflowY = 'hidden';
			} else {
				if (
					window.scrollY <= 30 &&
					!['/nos-produits', '/contact'].includes(this.props.location.pathname)
				) {
					if (this.isMobile) this.html!.style.overflow = 'hidden';
				}
			}
		}
	}

	handleScroll = (e: WheelEvent) => {
		if (e.deltaY > 0) this.goDown();
		else if (e.deltaY < 0) this.goUp();
	};

	handleKeyboard = (e: KeyboardEvent) => {
		if (e.key === 'ArrowDown') this.goDown();
		else if (e.key === 'ArrowUp') this.goUp();
	};

	handleTouchStart = (e: TouchEvent) => {
		if (e.touches.length !== 1) {
			return;
		}

		const { clientY } = e.touches[0];
		this.touchStart = clientY;
	};

	handleTouchMove = (e: TouchEvent) => {
		e.preventDefault();
		const { clientY } = e.touches[0];
		const diff = this.touchStart - clientY;
		if (diff > touchLimit) {
			this.goDown();
		} else if (diff < -touchLimit) {
			this.goUp();
		}
	};

	componentDidMount() {
		window.addEventListener('wheel', this.handleScroll);
		window.addEventListener('keydown', this.handleKeyboard);
		window.addEventListener('touchstart', this.handleTouchStart);
		window.addEventListener('touchmove', this.handleTouchMove);

		this.ifMobile();
	}

	setAnimate = () => {
		document.body.style.overflowY = 'hidden';
		this.animated = true;
		setTimeout(() => {
			if (this.isMobile) document.body.style.overflowY = 'overlay';
			this.animated = false;
		}, 1000);
	};

	// function for IOS BOUNCE !
	ifMobile = () => {
		if (this.isMobile) {
			if (window.location.pathname === '/') {
				this.html!.style.overflow = 'hidden';
			} else {
				this.html!.style.overflow = 'inherit';
			}
		} else {
			if (window.location.pathname === '/') {
				this.html!.style.overflow = 'hidden';
			} else {
				this.html!.style.overflow = 'inherit';
			}
		}
	};

	componentWillUnmount() {
		window.removeEventListener('wheel', this.handleScroll);
		window.removeEventListener('keydown', this.handleKeyboard);
		window.removeEventListener('touchstart', this.handleTouchStart);
		window.removeEventListener('touchmove', this.handleTouchMove);
	}

	componentDidUpdate(prevprops: any) {
		let clickSaison;
		prevprops.location.state
			? (clickSaison = prevprops.location.state.clickSaison)
			: (clickSaison = false);

		const routeSeasonIndex = saisons.findIndex(
			(s) => '/' + s.slug === this.props.location.pathname,
		);
		if (routeSeasonIndex !== -1 && this.state.index !== routeSeasonIndex) {
			this.setState({ index: routeSeasonIndex });
		}

		if (!this.init && clickSaison) this.animated = true;
		this.init = false;

		this.ifMobile();

		if (
			!['/nos-produits', '/contact'].includes(this.props.location.pathname) &&
			!clickSaison &&
			prevprops.location.pathname !== '/'
		) {
			this.setAnimate();
		}
		if (prevprops.location.state) prevprops.location.state.clickSaison = false;
	}

	render() {
		// Comme on ne peut pas utiliser de Switch avec la technique de transition de routes,
		// on doit faire le Redirect séparément
		const { pathname } = this.props.location;
		const doesRouteExist =
			routes.find((r) => r.path === pathname) || pathname === '/';
		if (!doesRouteExist) return <Redirect to="/" />;

		return (
			<>
				{this.props.location.pathname === '/' && this.init ? <Intro /> : ''}
				<Header />

				{/* Home */}
				<AnimatedRoute path="/">
					<Home
						index={this.state.index}
						dragging={this.state.dragging}
						setIndex={(index) => this.setState({ index })}
						setDragging={(dragging) => this.setState({ dragging })}
					/>
				</AnimatedRoute>

				{/* Other pages */}
				<ParallaxProvider>
					{routes.map(({ Component, path }) => (
						<AnimatedRoute key={path} path={path}>
							<Component />
						</AnimatedRoute>
					))}
				</ParallaxProvider>

				<WebGL
					index={this.state.index}
					dragging={this.state.dragging}
					isRendering={this.props.location.pathname === '/'}
				/>
				<Mouse />
			</>
		);
	}
}

export default withRouter(App);
