// Sons
import automneMp3 from './sons/automne.mp3';
import hiverMp3 from './sons/hiver.mp3';
import printempsMp3 from './sons/printemps.mp3';
import eteMp3 from './sons/ete.mp3';

// Videos
import automneVideos from './videos/automne';
import eteVideos from './videos/ete';
import hiverVideos from './videos/hiver';
import printempsVideos from './videos/printemps';

interface Saison extends VideoSources {
	title: string;
	slug: string;
	mp3: string;
	isNow: (d: number) => boolean;
}

// start et end sont au format "mdd" (mois + jour en 2 chiffres)
export const saisons: Saison[] = [
	{
		title: 'Automne',
		slug: 'automne',
		...automneVideos,
		mp3: automneMp3,
		isNow: d => d >= 923 && d <= 1220,
	},
	{
		title: 'Hiver',
		slug: 'hiver',
		...hiverVideos,
		mp3: hiverMp3,
		isNow: d => d >= 1221 || d <= 320,
	},
	{
		title: 'Printemps',
		slug: 'printemps',
		...printempsVideos,
		mp3: printempsMp3,
		isNow: d => d >= 321 && d <= 621,
	},
	{
		title: 'Été',
		slug: 'ete',
		...eteVideos,
		mp3: eteMp3,
		isNow: d => d >= 622 && d <= 922,
	},
];
