import React, { Component } from 'react';
import styles from './Header.module.scss';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { ReactComponent as LogoSVG } from './logo-halles-trottemant.svg';
import { ReactComponent as PictoSVG } from './picto.svg';
import Burger from '../Burger/Burger';
import Menu from '../Menu/Menu';

interface HeaderState {
	menuOpen: boolean;
	headerHidden: boolean;
}

class Header extends Component<RouteComponentProps, HeaderState> {
	touchStart = 0;
	state = {
		menuOpen: false,
		headerHidden: false,
	};

	handleScroll = (e: WheelEvent) => {
		if (window.scrollY > 0 && !this.state.menuOpen) {
			this.setState({ headerHidden: e.deltaY > 0 });
		}
	};

	handleTouchStart = (e: TouchEvent) => {
		if (e.touches.length !== 1) {
			return;
		}

		const { clientY } = e.touches[0];
		this.touchStart = clientY;
	};

	handleTouchMove = (e: TouchEvent) => {
		e.preventDefault();
		const { clientY } = e.touches[0];
		if (window.scrollY <= 50) {
			if (!this.state.headerHidden) {
				this.setState({ headerHidden: false });
			}
		} else {
			if (this.touchStart > clientY + 10) {
				if (!this.state.headerHidden) this.setState({ headerHidden: true });
			} else if (this.touchStart < clientY - 10) {
				if (this.state.headerHidden) this.setState({ headerHidden: false });
			}
		}
	};

	componentDidMount() {
		window.addEventListener('wheel', this.handleScroll);
		window.addEventListener('touchstart', this.handleTouchStart);
		window.addEventListener('touchmove', this.handleTouchMove);

		if (window.location.pathname !== '/') {
			this.burgerToogle(true);
		}
	}

	componentWillUnmount() {
		window.removeEventListener('wheel', this.handleScroll);
		window.removeEventListener('touchstart', this.handleTouchStart);
		window.removeEventListener('touchmove', this.handleTouchMove);
	}

	burgerToogle(init: boolean) {
		if (!init) this.setState({ menuOpen: !this.state.menuOpen });

		let color = 'white';
		if (window.location.pathname !== '/')
			!this.state.menuOpen ? (color = 'white') : (color = '#e72a7e');

		if (init) color = '#e72a7e';
		setTimeout(() => {
			let svg = document.querySelector('#logo-header');
			svg!.children[1].setAttribute('fill', color);
			svg!.children[2].setAttribute('stroke', color);
			svg!.children[3].setAttribute('stroke', color);
			svg!.children[4].setAttribute('stroke', color);
			svg!.children[5].setAttribute('fill', color);
			svg!.children[6].setAttribute('fill', color);
			let burgerLines = document.getElementsByClassName(
				'line',
			) as HTMLCollectionOf<HTMLElement>;
			for (let i = 0; i < burgerLines.length; i++) {
				burgerLines[i].style.background = color;
			}
		}, 75);
	}

	render() {
		const isHome = this.props.location.pathname === '/';
		const TitleTag = isHome ? 'h1' : 'p';
		const isHidden = isHome ? false : this.state.headerHidden;

		return (
			<header className={styles.wrapper}>
				<TitleTag className={styles.logo}>
					<Link to="/">
						<LogoSVG
							className={[styles.logo, isHidden && styles.hidden]
								.filter(Boolean)
								.join(' ')}
						/>
					</Link>
				</TitleTag>

				<div className={styles.divHeadRight}>
					<a target="_blank" href="https://app.hallestrottemant.fr/" className={[!isHome ? styles.svgRed : styles.svg, isHidden && styles.hidden]
						.filter(Boolean)
						.join(' ')}>

						<PictoSVG className={[!isHome ? styles.red : styles.white]
							.filter(Boolean)
							.join(' ')} />
					</a>

					<Burger
						isOpen={this.state.menuOpen}
						isHidden={isHidden}
						onClick={() => this.burgerToogle(false)}
					/>
				</div>

				<Menu
					isOpen={this.state.menuOpen}
					closeMenu={() => this.burgerToogle(false)}
				/>
			</header>
		);
	}
}

export default withRouter(Header);
