import React from 'react';
import styles from './Menu.module.scss';
import { Link } from 'react-router-dom';
import { pages } from './pages';

interface MenuProps {
	isOpen: boolean;
	closeMenu: () => void;
}

function Menu({ isOpen, closeMenu }: MenuProps) {
	return (
		<nav
			onClick={closeMenu}
			className={[styles.wrapper, isOpen && styles.isOpen]
				.filter(Boolean)
				.join(' ')}
		>
			<ul className={styles.list}>
				{pages.map(({ title, subtitle, path }, i) => (
					<li key={path}>
						<Link
							to={path}
							className={path !== '/mentions-legales' ? styles.link : styles.mentionsLegalesLink}
							style={{ transitionDelay: i * 50 + 'ms' }}
						>
							<span className={title !== "Mentions legales" ? styles.big : styles.mentionLegalesTitle}>{title}</span>
							<span className={subtitle !== "Mentions Legales" ? styles.small : styles.mentionLegalesSubtitle}>{subtitle}</span>
						</Link>
					</li>
				))}
			</ul>
		</nav>
	);
}

export default Menu;
