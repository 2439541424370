import React, { useState } from 'react';
import Media from 'react-responsive';
import styles from './Hiver.module.scss';
import Column from '../../components/Column/Column';
import { media } from '../../utils/breakpoints/breakpoints';
import { Parallax } from 'react-scroll-parallax';
import PageTitle from '../../components/PageTitle/PageTitle';
import PageSub from '../../components/PageSub/PageSub';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import SectionText from '../../components/SectionText/SectionText';
import feuille from './img/feuille.png';
import radis from './img/radis.jpg';
import agrumes from './img/agrumes.jpg';
import orange from './img/orange.png';
import corse from './img/corse.png';
import quartierOrange from './img/quartierOrange.png';
import Vignette from '../../components/Vignette/Vignette';
import Footer from '../../components/Footer/Footer';
import Saison from '../../components/Saison/Saison';
import { useHistory } from 'react-router';
import Head from '../../components/Head/Head';
import metaImg from './img/hiver.jpg';
// import { ReactComponent as Corse } from'./img/corse.svg';

function Hiver() {
	const [isTablet] = useState(window.innerWidth <= 1024);
	const [clickSaison, setclickSaison] = useState(false);
	const [saison, setSaison] = useState(false);
	const history = useHistory();

	function retourHome() {
		setclickSaison(true);
		setSaison(true);
		setTimeout(() => {
			history.push({
				pathname: '/',
				state: { clickSaison: true },
			});
		}, 1550);
	}

	return (
		<div className={styles.page}>
			<Head title="Hiver" description="" img={metaImg} />

			{/* <Column className={styles.page}> */}
			<section className={styles.hautPage}>
				<img
					className={[styles.quartierOrange, styles.imgGauche].join(' ')}
					src={quartierOrange}
					alt=""
				/>
				<img
					className={[styles.feuille, styles.imgDroite].join(' ')}
					src={feuille}
					alt=""
				/>
				{/* <Parallax disabled={isTablet} y={['25px', '-75px']}> */}
				<PageTitle titre1="Un plein" titre2="de vitalité" />
				{/* </Parallax> */}
				{/* <Parallax disabled={isTablet} y={['50px', '-50px']}> */}
				<PageSub
					text1="« Des fruits et des légumes de saison pour des"
					text2="recettes vitaminées »"
					text3=""
				/>
				{/* </Parallax> */}
			</section>
			<section className={styles.partie1}>
				<div className={styles.wrapperSection}>
					<Column className={[styles.img, styles.imgAnim].join(' ')}>
						<Parallax disabled={isTablet} y={['80px', '-100px']}>
							<img src={orange} alt="" />
						</Parallax>
						<Parallax disabled={isTablet} y={['150px', '-100px']}>
							<Vignette
								className={styles.vignetteOrange}
								img={corse}
								titre=""
								text="La clémentine Corse
									est l'unique
									clémentine française
									et bénéficie d'une
									IGP garantissant sa
									qualité, sa traçabilité
									et sa fraîcheur."
							/>
						</Parallax>
					</Column>
					<Column className={[styles.text, styles.textAnim].join(' ')}>
						<Parallax disabled={isTablet} y={['-80px', '100px']}>
							<SectionTitle gauche titre1="La reine" titre2="de l'hiver" />
							<SectionText intro="La clémentine…">
								Fruit du hasard né d'un croisement de mandarine et d'orange, ce fruit
								hivernal met de l'énergie dans les assiettes.
								Récoltée sur l'île de beauté, cette région offre à sa clémentine des
								conditions climatiques exceptionnelles, pour être ensuite récoltée de
								manière tradition- nelle de Novembre à Janvier, selon les variétés.
							</SectionText>
						</Parallax>
					</Column>
				</div>
			</section>
			<section className={styles.partie2}>
				<div className={styles.wrapperSection}>
					<Media query={media['>mobile']}>
						<div className={styles.wrapper}>
							<Parallax disabled={isTablet} y={['-80px', '100px']}>
								<SectionTitle titre1="Au goût" titre2="du jour" />
							</Parallax>

							<Column className={styles.img}>
								<Parallax disabled={isTablet} y={['-50px', '50px']}>
									<img src={radis} alt="" />
								</Parallax>
								<Vignette
									className={styles.vignetteRadis}
									titre="- 200 km"
									text="L'ensemble de la gamme
										des légumes anciens est
										cultivée en agriculture
										raisonnée à moins de 
										200 km de Paris."
								/>
							</Column>
						</div>
						<Parallax disabled={isTablet} y={['80px', '-100px']}>
							<Column className={styles.text}>
								<SectionText
									intro="Les légumes anciens
										d'Arnaud Lespagnol"
									textPartOne="Tous nos légumes anciens proviennent des cultures
										de notre petit producteur local Arnaud Lespagnol.
										Ils sont disponibles de Octobre à Mars:">
									Topinambour, bettrave de couleur, carotte de couleur, panais, racine de
									persil, radis noir/red meat/green meat, oca du pérou, héliantis, navet de
									couleur, cerfeuil tubéreux, yacon, capucine tubéreuse, scorsonère,
									rutabaga....
								</SectionText>
							</Column>

							{/* <Column className={styles.img2}>
								<img src={fondFeuille} alt="" />
							</Column> */}
						</Parallax>
					</Media>
					<Media query={media['<=mobile']}>
						<Column className={styles.text}>
							<SectionTitle titre1="Au goût" titre2="du jour" />
							<SectionText
								intro="Les légumes anciens
									d'Arnaud Lespagnol"
								textPartOne="Tous nos légumes anciens proviennent des cultures
									de notre petit producteur local Arnaud Lespagnol.
									Ils sont disponibles de Octobre à Mars:">
								Topinambour, bettrave de couleur, carotte de couleur, panais, racine de
								persil, radis noir/red meat/green meat, oca du pérou, héliantis, navet de
								couleur, cerfeuil tubéreux, yacon, capucine tubéreuse, scorsonère,
								rutabaga....
							</SectionText>
						</Column>
						<div className={styles.wrapper}>
							<Column className={styles.img}>
								<Parallax disabled={isTablet} y={['-50px', '50px']}>
									<img src={radis} alt="" />
								</Parallax>
							</Column>
						</div>
						<div className={styles.wrapper2}>
							<Vignette
								className={[styles.vignetteRadis, styles.vignette].join(' ')}
								titre="- 200 km"
								text="L'ensemble de la gamme
								des légumes anciens est
								cultivée en agriculture
								raisonnée à moins de 
								200 km de Paris."
							/>
						</div>
						{/* <div className={styles.wrapper3}>
							<img src={fondFeuille} alt="" />
						</div> */}
					</Media>
				</div>
			</section>

			<section
				className={[
					styles.partie3,
					clickSaison ? styles.deconstruct : styles.off,
				].join(' ')}
			>
				<div className={styles.wrapperSection}>
					<Media query={media['>mobile']}>
						<Column className={styles.img3}>
							<Parallax disabled={isTablet} y={['80px', '-100px']}>
								<img src={agrumes} alt="" />
							</Parallax>
							<Parallax disabled={isTablet} y={['150px', '-100px']}>
								<Vignette
									className={styles.vignetteAgrumes}
									titre="1000"
									text="variétés d'agrumes
										sont abritées par la
										pépinière sur 2
										hectares de verger."
								/>
							</Parallax>
						</Column>

						<Column className={styles.text}>
							<Parallax disabled={isTablet} y={['-80px', '100px']}>
								<SectionTitle
									className={styles.titre}
									gauche
									titre1="Juste"
									titre2="un zeste"
								// titre3="faire"
								/>
								<Media query={media['>laptop']}>
									<SectionText
										intro="Les célèbres Agrumes Bachès..."
										className={styles.text3Desktop}
									>
										Perrine et Etienne Schaller cultivent et créent de nouvelles variétés
										d'agrumes pour le monde de la gastronomie. Ils sont installés avec
										leur équipe passionnée sur la commune d'Eus, véritable terroir
										d'exception au pied du mont Canigou dans les Pyrénées-Orientales.
										Lorsqu'un chef cherche de l'acidité, de la fraîcheur, de l'amertume
										ou bien encore de la texture pour équilibrer un plat, c'est tout
										naturellement vers eux qu'il se tourne et pioche parmi leur
										centaines de variétés.
									</SectionText>
								</Media>
							</Parallax>
						</Column>
					</Media>
					<Media query={media['<=mobile']}>
						<Column className={styles.text}>
							<Parallax disabled={isTablet} y={['-80px', '100px']}>
								<SectionTitle
									className={styles.titre}
									gauche
									titre1="Juste"
									titre2="un zeste"
								// titre3="faire"
								/>
								<SectionText intro="Les célèbres Agrumes Bachès...">
									Perrine et Etienne Schaller cultivent et créent de nouvelles variétés
									d'agrumes pour le monde de la gastronomie. Ils sont installés avec
									leur équipe passionnée sur la commune d'Eus, véritable terroir
									d'exception au pied du mont Canigou dans les Pyrénées-Orientales.
									Lorsqu'un chef cherche de l'acidité, de la fraîcheur, de l'amertume
									ou bien encore de la texture pour équilibrer un plat, c'est tout
									naturellement vers eux qu'il se tourne et pioche parmi leur
									centaines de variétés.
								</SectionText>
							</Parallax>
						</Column>
						<Column className={styles.img3}>
							<Parallax disabled={isTablet} y={['80px', '-100px']}>
								<img src={agrumes} alt="agrumes" />
							</Parallax>{' '}
							<Vignette
								className={styles.vignetteAgrumes}
								titre="1000"
								text="variétés d'agrumes
									sont abritées par la
									pépinière sur 2
									hectares de verger."
							/>
						</Column>
					</Media>
				</div>
				<Media query={media['<=laptop']}>
					<Media query={media['>mobile']}>
						<SectionText className={styles.text3} intro="Les célèbres Agrumes Bachès...">
							Perrine et Etienne Schaller cultivent et créent de nouvelles variétés
							d'agrumes pour le monde de la gastronomie. Ils sont installés avec
							leur équipe passionnée sur la commune d'Eus, véritable terroir
							d'exception au pied du mont Canigou dans les Pyrénées-Orientales.
							Lorsqu'un chef cherche de l'acidité, de la fraîcheur, de l'amertume
							ou bien encore de la texture pour équilibrer un plat, c'est tout
							naturellement vers eux qu'il se tourne et pioche parmi leur
							centaines de variétés.
						</SectionText>
					</Media>
				</Media>
				<Footer></Footer>
			</section>
			<div
				className={[styles.saison, saison ? styles.center : styles.off].join(
					' ',
				)}
				onClick={retourHome}
			>
				<Saison clickSaison={clickSaison}></Saison>
			</div>
		</div>
	);
}

export default Hiver;
