// Mp4
import automne_mp4_320x240 from './automne-320x240.mp4';
import automne_mp4_640x480 from './automne-640x480.mp4';
import automne_mp4_1280x720 from './automne-1280x720.mp4';
import automne_mp4_1920x1080 from './automne-1920x1080.mp4';

// Webm
import automne_webm_320x240 from './automne-320x240.webm';
import automne_webm_640x480 from './automne-640x480.webm';
import automne_webm_1280x720 from './automne-1280x720.webm';
import automne_webm_1920x1080 from './automne-1920x1080.webm';

export default {
	mp4: {
		'320x240': automne_mp4_320x240,
		'640x480': automne_mp4_640x480,
		'1280x720': automne_mp4_1280x720,
		'1920x1080': automne_mp4_1920x1080,
	},
	webm: {
		'320x240': automne_webm_320x240,
		'640x480': automne_webm_640x480,
		'1280x720': automne_webm_1280x720,
		'1920x1080': automne_webm_1920x1080,
	},
} as VideoSources;
