import React, { Component, createRef } from 'react';
import styles from './Intro.module.scss';
import { ReactComponent as LogoSVG } from './logo-halles-trottemant.svg';

class Intro extends Component {
	container = createRef<HTMLDivElement>();

	componentDidMount() {
		document.body.style.overflowY = 'hidden';
		setTimeout(() => {
			//document.body.style.overflowY = 'overlay';
			if (this.container.current) {
				this.container.current.style.display = 'none';
			}
		}, 2900);
	}

	render() {
		return (
			<div ref={this.container} className={styles.wrapper}>
				<LogoSVG className={styles.introLogo} />
			</div>
		);
	}
}

export default Intro;
