import React from 'react';
import styles from './Saison.module.scss';

export interface SaisonProps {
	clickSaison: boolean;
}

function Saison({ clickSaison }: SaisonProps) {
	let d =
		'M1206 124c-24.8 0-40.7-11.8-47.3-29.2l23.3-13.5a24.8 24.8 0 0024.9 16.3c12.8 0 16-5 16-9.6 0-7.3-6.7-10.1-24.5-15-17.5-5-34.8-13.4-34.8-36 0-22.8 19.3-36 39.7-36 19.5 0 34.7 9.3 43.3 26.7L1223.8 41c-4-8.4-9.6-13.6-20.5-13.6-8.4 0-12.6 4.2-12.6 9 0 5.3 2.8 9 21.3 14.6 17.9 5.4 38 11.7 38 36.7 0 22.8-18.3 36.3-44 36.3zM1093 3.4h27v118.2h-20.3l-45.6-64.2v64.2h-27V3.4h20.2l45.7 64.2zM924.3 97.6c19.3 0 34.5-14 34.5-35.1a33.6 33.6 0 00-34.5-35.1c-19.3 0-34.5 14-34.5 35a33.6 33.6 0 0034.5 35.2zm0 26.4c-34 0-61.6-26.7-61.6-61.5S890.3 1 924.3 1s61.5 26.7 61.5 61.5-27.5 61.5-61.5 61.5zm-139.1 0c-24.9 0-40.8-11.8-47.4-29.2l23.4-13.5A24.8 24.8 0 00786 97.6c12.9 0 16-5 16-9.6 0-7.3-6.7-10.1-24.4-15-17.6-5-34.9-13.4-34.9-36 0-22.8 19.3-36 39.8-36 19.4 0 34.6 9.3 43.2 26.7L803 41c-4-8.4-9.6-13.6-20.4-13.6-8.5 0-12.7 4.2-12.7 9 0 5.3 2.9 9 21.3 14.6 18 5.4 38 11.7 38 36.7 0 22.8-18.2 36.3-44 36.3zM672 3.4h27v118.2h-27zM564.6 77.7h27.7l-13.8-43.4zm41.8 44l-6-18.7h-44l-5.8 18.6H521L561.2 3.4h34.5L636 121.6zm-157 2.3c-25 0-40.8-11.8-47.4-29.2l23.3-13.5a24.8 24.8 0 0024.9 16.3c12.8 0 16-5 16-9.6 0-7.3-6.7-10.1-24.5-15-17.6-5-34.8-13.4-34.8-36 0-22.8 19.3-36 39.7-36 19.5 0 34.7 9.3 43.3 26.7L467.1 41c-4-8.4-9.7-13.6-20.5-13.6-8.4 0-12.7 4.2-12.7 9 0 5.3 3 9 21.4 14.6 17.9 5.4 38 11.7 38 36.7 0 22.8-18.3 36.3-44 36.3zM265 124c-24.8 0-40.7-11.8-47.3-29.2l23.4-13.5a24.8 24.8 0 0024.8 16.3c12.9 0 16-5 16-9.6 0-7.3-6.7-10.1-24.4-15-17.6-5-34.8-13.4-34.8-36 0-22.8 19.2-36 39.7-36 19.4 0 34.6 9.3 43.2 26.7L282.8 41c-4-8.4-9.6-13.6-20.4-13.6-8.5 0-12.7 4.2-12.7 9 0 5.3 2.9 9 21.3 14.6 17.9 5.4 38 11.7 38 36.7 0 22.8-18.3 36.3-44 36.3zM135.2 95.6h47.4v26H108V3.4h73.5v26h-46.5V49h42.3v25.7H135zm-107 0h42.2v26H1V3.4h27z';
	return (
		<div className={[clickSaison ? styles.clicked : styles.wrapper].join(' ')}>
			<svg width="1250" height="125" viewBox="0 0 1250 125">
				<defs>
					<clipPath id="path">
						<path fill="none" stroke="#000" strokeWidth="1" d={d} />
					</clipPath>
				</defs>
				<g clipPath="url(#path)">
					<circle cx="850" cy="0" r="500" fill="#000" />
				</g>
				<path fill="none" stroke="#000" strokeWidth="1" d={d} />
			</svg>
		</div>
	);
}

export default Saison;
