import React from 'react';
import { hydrate, render } from 'react-dom';
import App from './App/App';
import * as serviceWorker from './serviceWorker';
import 'destyle.css';
import './styles/style.scss';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

// On empêche le navigateur de restaurer le scroll avec history
if ('scrollRestoration' in window.history) {
	window.history.scrollRestoration = 'manual';
}

const app = (
	<BrowserRouter>
		<App />
		<ScrollToTop />
	</BrowserRouter>
);

const rootElement = document.getElementById('root');
if (rootElement!.hasChildNodes()) {
	hydrate(app, rootElement);
} else {
	render(app, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
