import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { pageTransitionDuration } from '../AnimatedRoute/AnimatedRoute';

function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		setTimeout(() => window.scrollTo(0, 0), pageTransitionDuration);
	}, [pathname]);

	return null;
}

export default ScrollToTop;
