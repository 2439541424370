export const pages: { path: string; title: string; subtitle: string }[] = [
	{
		path: '/',
		title: '4 saisons',
		subtitle: 'Notre sélection de saveurs pour chaque saison',
	},
	{
		path: '/notre-histoire',
		title: 'Les Halles Trottemant',
		subtitle: 'Notre histoire & nos valeurs',
	},
	{
		path: '/nos-produits',
		title: 'Produits de saison',
		subtitle: 'Découvrez notre offre mois après mois',
	},
	{
		path: '/contact',
		title: 'Contactez-nous',
		subtitle: "Besoin d'un renseignement ?",
	},
	{
		path: '/mentions-legales',
		title: 'Mentions legales',
		subtitle: "Mentions Legales",
	},
];
