import React from 'react';
import styles from './CallToActionHome.module.scss';
import { Link } from 'react-router-dom';

interface CallToActionHomeProps {
	href: string;
}

function CallToActionHome({ href }: CallToActionHomeProps) {
	return (
		<Link to={href} className={styles.wrapper}>
			<div className={styles.line} />
			<span className={styles.text}>Découvrir</span>
		</Link>
	);
}

export default CallToActionHome;
