import React from 'react';
import styles from './MentionLegal.module.scss';

function MentionLegal() {
	return (
		<div className={styles.wrapper}>
			<h1 className={styles.title}>Mentions Legales</h1>
			<p className={styles.description}>
				Notre entreprise poursuit ses actions concourant à l’égalité
				professionnelle entre les femmes et les hommes et, plus largement,
				veille au respect de toutes les égalités de traitement entre ses
				salariés en matière d’embauche, de rémunération, de formation et de
				déroulement de carrière. Notre Index de l’égalité professionnelle pour
				2022 est de 53/100. Avant application de la règle de proportionnalité
				pour les indicateurs incalculables, notre Index de l’égalité
				professionnelle se décompose par indicateurs de la manière suivante :
				0/40 pour l’écart de rémunération, 35/35 pour l’écart de répartition des
				augmentations individuelles, 0/15 pour le nombre de salariées augmentées
				à leur retour de congé maternité (indicateur incalculable par absence de
				salariée de retour de congé maternité cette année) et 10/10 pour la
				parité parmi les 10 plus hautes rémunérations.
			</p>
		</div>
	);
}

export default MentionLegal;
