import React from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router';

const siteName = process.env.REACT_APP_SITE_NAME;
const siteUrl = process.env.REACT_APP_SITE_URL;
const isProd = process.env.REACT_APP_IS_PROD === 'true';

interface HeadProps {
	img?: string;
	title: string;
	description: string;
}

function Head({ title, description, img }: HeadProps) {
	const { pathname } = useLocation();
	const fullTitle = `${title} - ${siteName}`;
	const url = siteUrl + pathname;

	return createPortal(
		<>
			{!isProd && <meta name="robots" content="noindex, nofollow" />}

			{/* Primary Meta Tags */}
			<title>{fullTitle}</title>
			<meta name="title" content={fullTitle} />
			<meta name="description" content={description} />

			{/* Open Graph / Facebook */}
			<meta property="og:type" content="website" />
			<meta property="og:url" content={url} />
			<meta property="og:title" content={fullTitle} />
			<meta property="og:description" content={description} />
			{img && <meta property="og:image" content={siteUrl + img} />}

			{/* Twitter */}
			<meta property="twitter:card" content="summary" />
			<meta property="twitter:url" content={url} />
			<meta property="twitter:title" content={fullTitle} />
			<meta property="twitter:description" content={description} />
			{img && <meta property="twitter:image" content={siteUrl + img} />}
		</>,
		document.head,
	);
}

export default Head;
